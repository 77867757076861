import { PureComponent } from "react";
import styles from "./LastNews.module.scss";
import { NavLink } from "react-router-dom";
import "./slick.css";
import "./slick-theme.css";
import Slider from "react-slick";
import cur_ph_1 from "../../img/banner_pic_pn1.jpg";
import cur_ph_2 from "../../img/banner_pic_pn2.jpg";
import cur_ph_3 from "../../img/banner_pic_pn3.jpg";
import cur_ph_4 from "../../img/banner_pic_1.jpg";
import cur_ph_5 from "../../img/banner_pic_2.jpg";
import cur_ph_6 from "../../img/banner_pic_3.jpg";


function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", zIndex: "3" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", left: "1px" }}
      onClick={onClick}
    />
  );
}

class LastNews extends PureComponent {
  render() {
    const imgEl = [cur_ph_4, cur_ph_5, cur_ph_6];
    const imgEl2 = [cur_ph_1, cur_ph_2, cur_ph_3];

    const navEl = ["/", "/", "/"];

    const settings_1 = {
      dots: true,
      infinite: true,
      centerMode: true,
      centerPadding: "0px",
      arrows: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      autoplay: true,
      autoplaySpeed: 2000,
      pauseOnHover: true,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
        {
          breakpoint: 1280,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
            arrows: false,
            centerMode: false,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
            arrows: false,
            centerMode: false,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
            arrows: false,
          },
        },
        {
          breakpoint: 600,
          settings: {
            dots: true,
            infinite: true,
            centerMode: true,
            arrows: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 0,
          },
        },
      ],
    };

    const settings_2 = {
      dots: true,
      infinite: true,
      centerMode: true,
      centerPadding: "0px",
      arrows: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      autoplay: true,
      autoplaySpeed: 2000,
      pauseOnHover: true,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
        {
          breakpoint: 1280,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
            arrows: false,
            centerMode: false,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
            arrows: false,
            centerMode: false,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
            arrows: false,
          },
        },
        {
          breakpoint: 600,
          settings: {
            dots: true,
            infinite: true,
            centerMode: true,
            arrows: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 0,
          },
        },
      ],
    };

    return (
      <div className={styles.root}>
        <div className={styles.slider_main}>
          <div className={styles.slider_430}>
            <Slider {...settings_1} style={{ width: "100vw" }}>
              {imgEl2.map((el, index) => (
                <NavLink
                  to={navEl[index]}
                  key={index}
                  style={({ isActive, isPending }) => {
                    return {
                      fontWeight: isActive ? "bold" : "",
                      color: isPending ? "darkgrey" : "black",
                      textDecoration: "none",
                    };
                  }}
                >
                  <img
                    key={index}
                    className={styles.cur_foto}
                    src={el}
                    alt={index}
                  ></img>
                </NavLink>
              ))}
            </Slider>
          </div>
          <div className={styles.slider_768}>
            <Slider {...settings_2} style={{ width: "100vw" }}>
              {imgEl.map((el, index) => (
                <NavLink
                  to={navEl[index]}
                  key={index}
                  style={({ isActive, isPending }) => {
                    return {
                      fontWeight: isActive ? "bold" : "",
                      color: isPending ? "darkgrey" : "black",
                      textDecoration: "none",
                    };
                  }}
                >
                  <img
                    key={index}
                    className={styles.cur_foto}
                    src={el}
                    alt={index}
                  ></img>
                </NavLink>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    );
  }
}

export default LastNews;
