import { createSlice } from "@reduxjs/toolkit"; // with immer and produce

const idNewsSlice = createSlice({
    name: 'idNews',
    initialState: {
        isNews: {"id":"6"},
        isTitle: {"title":"Оберіть новину"},
        isText: {"text":"Будь ласка, поверніться до сторінки з новинами та оберіть ту, що зацікавила"},
        isData: {"dataPost":""},
        isImg: {"img":""},
        isImg2: {"img2":""},
        isImg3: {"img3":""},
        isBrand: {"brand":"new_news"}
    },
    reducers: {
        setIdNews: (state, action) => {
            state.isNews = action.payload;
        },

        setIsTitle: (state, action) => {
            state.isTitle = action.payload;
        },

        setIsText: (state, action) => {
            state.isText = action.payload;
        },

        setIsData: (state, action) => {
            state.isData = action.payload;
        },

        setIsImg: (state, action) => {
            state.isImg = action.payload;
        },
        setIsImg2: (state, action) => {
            state.isImg2 = action.payload;
        },

        setIsImg3: (state, action) => {
            state.isImg3 = action.payload;
        },
        
        
        setIsBrand: (state, action) => {
            state.isBrand = action.payload;
        },

    
    }
});

export const { setIdNews, setIsTitle, setIsText, setIsData, setIsImg, setIsImg2, setIsImg3, setIsBrand } = idNewsSlice.actions;

export default idNewsSlice.reducer;