import React from "react";
import { useSwipeable } from "react-swipeable";
import styles from "./DeviceItemPage.module.scss";

const SwipeableWrapper = ({ onSwipedLeft, onSwipedRight, children }) => {
  const handlers = useSwipeable({
    onSwipedLeft,
    onSwipedRight,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  return (
    <div className={styles.product_img_div} {...handlers}>
      {children}
    </div>
  );
};

export default SwipeableWrapper;
