import { createSlice } from "@reduxjs/toolkit"; // with immer and produce

const modalSlice = createSlice({
    name: 'modal',
    initialState: {
        isOpen: false,
        modalData: {}
    },
    reducers: {
        setIsOpen: (state, action) => {
            state.isOpen = action.payload;
        },

        setModalData: (state, action) => {
            state.modalData = action.payload;
        },
    }
});

export const { setIsOpen, setModalData } = modalSlice.actions;

export default modalSlice.reducer;