import { PureComponent } from "react";
import styles from "./UnitechSystemsPage.module.scss";
import { Helmet } from "react-helmet";
import Footer from "../../components/Footer/Footer";
import unitech_1 from "../../img/Unitech System/unitech-1.png";
import unitech_2 from "../../img/Unitech System/unitech-2.png";
import OurBrandsCatalog from "../../components/OurBrandsCatalog/OurBrandsCatalog";

class UnitechSystemsPage extends PureComponent {
  scrollto = () => {
    window.scrollTo(0, 0);
  };

  componentDidMount() {
    this.scrollto();
  }
  render() {
    return (
      <div className={styles.uniSysPageRoot}>
        <Helmet>
          <title>Unitech Systems</title>
          <meta
            name="description"
            content="Unitech Systems - розробляє та виготовляє високоякісні кріплення та механізми для всіх видів аудіо- та відеотехніки. Це індивідуально розроблені продукти. Це широкий і постійно розширюваний асортимент."
          />
          <link
            rel="canonical"
            href="https://brands.d8.com.ua/unitech-systems"
          />
        </Helmet>
        <div className={styles.uniSysPage}>
          <div className={styles.unitech}>
            <h1 className={styles.unitech_article}>Про Unitech Systems</h1>
            <div className={styles.unitech_div_1}>
              <img src={unitech_1} className={styles.unitech_photo_1} alt="unitech audio video"/>
              <div className={styles.unitech_inside_1}>
                <h2 className={styles.unitech_subarcticle_1}>Походження</h2>
                <p className={styles.unitech_describe_1}>
                  Unitech Systems - це компанія, що швидко розвивається,
                  заснована в 2004 році, яка розробляє та виготовляє кріплення
                  та механізми для всіх видів аудіо- та відеотехніки. Основна
                  увага Unitech Systems зосереджена на полегшенні та оптимізації
                  використання аудіо/візуального обладнання за допомогою
                  високоякісної продукції. Виходячи з цього, ми постійно
                  розвиваємося і вдосконалюємо наш асортимент продукції, щоб
                  задовольнити потреби нової епохи.
                </p>
              </div>
            </div>

            <div className={styles.unitech_div_2}>
              <img src={unitech_2} className={styles.unitech_photo_2} alt="unitech strategu design" />
              <div className={styles.unitech_inside_2}>
                <h2 className={styles.unitech_subarcticle_2}>Підхід</h2>
                <p className={styles.unitech_describe_2}>
                  Компанія має сучасний підхід у секторі підтримки
                  аудіо-візуального обладнання. Маючи гнучку філософію в
                  розробці продуктів, Unitech Systems слідкує за всіма
                  технологічними розробками, таким чином, маючи можливість
                  пропонувати сучасні продукти та рішення, використовуючи
                  передові та актуальні технології.
                </p>
              </div>
            </div>

            <div className={styles.unitech_div_3}>
              <h2 className={styles.unitech_subarcticle_3}>
                Чому обирають Unitech Systems
              </h2>
              <div className={styles.unitech_inside_3}>
                <p className={styles.unitech_grid_1}>1.</p>
                <p className={styles.unitech_grid_1_1}>
                  Компанія, що постійно розвивається з 2004 року, з великим
                  професійним досвідом.
                </p>

                <p className={styles.unitech_grid_2}>2.</p>
                <p className={styles.unitech_grid_2_1}>
                  Найкраще співвідношення ціни та якості в поєднаннні з
                  неперевершеним обслуговуванням клієнтів.
                </p>

                <p className={styles.unitech_grid_3}>3.</p>
                <p className={styles.unitech_grid_3_1}>
                  Унікальні у світі високоякісні продукти з токчи зору технічних
                  характеристик та користі.
                </p>

                <p className={styles.unitech_grid_4}>4.</p>
                <p className={styles.unitech_grid_4_1}>
                  Широкий і постійно розширюваний асортимент кріплень і
                  механізмів для всіх видів застосування - як побутового, так і
                  професійного.
                </p>

                <p className={styles.unitech_grid_5}>5.</p>
                <p className={styles.unitech_grid_5_1}>
                  Спеціалізація на стельових, настінних/меблевих або
                  настільних/підлогових механізмах для аудіо/відео обладнання.
                </p>

                <p className={styles.unitech_grid_6}>6.</p>
                <p className={styles.unitech_grid_6_1}>
                  Індивідуально розроблені продукти для задоволення потреб
                  клієнта.
                </p>

                <p className={styles.unitech_grid_7}>7.</p>
                <p className={styles.unitech_grid_7_1}>
                  Фахівці-дизайнери готові поділитися своїм досвідом і надати
                  унікальну послугу «останньої милі» для кожного проєкту.
                </p>

                <p className={styles.unitech_grid_8}>8.</p>
                <p className={styles.unitech_grid_8_1}>
                  Чесність і лояльність.
                </p>

                <p className={styles.unitech_grid_9}>9.</p>
                <p className={styles.unitech_grid_9_1}>
                  Філософія відкритого розуму (open-minded philosophy).
                </p>
              </div>
            </div>
            <OurBrandsCatalog />
          </div>

          <Footer />
        </div>
      </div>
    );
  }
}

export default UnitechSystemsPage;
