import React, { Component } from "react";
import SwipeableWrapper from "./DeviceItemPage_Swipe";
import styles from "./DeviceItemPage.module.scss";

// const fetchPhotos = async ({ products }) => {
//   const photosArray = products
//     .filter((product) => product.img)
//     .map((product) => product.img);

//   return photosArray;
// };
const fetchPhotos = async ({ products }) => {
  const photosArray = [];

  products.forEach((product) => {
    for (let i = 0; i <= 6; i++) {
      const imgKey = i === 0 ? "img" : `img${i}`;
      if (product[imgKey]) {
        photosArray.push(product[imgKey]);
      }
    }
  });

  return photosArray;
};

class DeviceItemPagePart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      photos: [],
      currentIndex: 0,
      fade: true,
    };
  }

  async componentDidMount() {
    const title = this.props.title;
    const describe = this.props.describe;
    const newDescribe = document.getElementById(`${title}`);
    newDescribe.innerHTML = describe;
    const photosArr = this.props.photos;
    const id = this.props.id;
    // console.log(photosArr);    
    const photos = await fetchPhotos({ products: photosArr, id: id });
    this.setState({ photos });
  }

  changePhoto = (newIndex) => {
    this.setState({ fade: false });
    setTimeout(() => {
      this.setState({ currentIndex: newIndex, fade: true });
    }, 400); // Duration of the fade-out transition
  };

  nextPhoto = () => {
    const { currentIndex, photos } = this.state;
    this.changePhoto((currentIndex + 1) % photos.length);
  };

  prevPhoto = () => {
    const { currentIndex, photos } = this.state;
    this.changePhoto((currentIndex - 1 + photos.length) % photos.length);
  };

  render() {
    const { photos, currentIndex, fade } = this.state;
    const { title, status } = this.props;

    return (
      <div className={styles.product_div}>
        {photos.length > 0 && (
          <div className={styles.img_div}>
            <button className={styles.btn_prev} onClick={this.prevPhoto}>
              <div className={styles.btn_prev_img} />
            </button>

            <SwipeableWrapper
              onSwipedLeft={this.nextPhoto}
              onSwipedRight={this.prevPhoto}
            >
              <img
                src={photos[currentIndex]}
                alt={title}
                className={`${styles.product_img} ${
                  fade ? styles.fade_in : styles.fade_out
                }`}
              />
            </SwipeableWrapper>
            <button className={styles.btn_next} onClick={this.nextPhoto}>
              <div className={styles.btn_next_img} />
            </button>
          </div>
        )}

        <div>
          <h1 className={styles.product_title}>{title}</h1>
          <p className={styles.product_describe} id={`${title}`}></p>

          <div className={styles.product_status_div}>
            {status === "available" ? (
              <p className={styles.product_status_available}>
                <a href="mailto:brands@d8.com.ua">В наявності</a>
              </p>
            ) : (
              <p className={styles.product_status_order}>
                <a href="mailto:brands@d8.com.ua">Під замовлення</a>
              </p>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default DeviceItemPagePart;
