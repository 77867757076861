import React, { useEffect, useMemo } from "react";
import styles from "./CatalogPage.module.scss";
import { Helmet } from "react-helmet";
import Footer from "../../components/Footer/Footer";
import search_icon from "../../img/Catalog/search.png";
import filter_btn from "../../img/Catalog/fltr_btn.png";
import FilterCatalogPhone from "../../components/FilterCatalogPhone/Filter_Catalog_Phone";
import FilterCatalogTablet from "../../components/FilterCatalogTablet/Filter_Catalog_Tablet";
import DeviceItem from "../../components/DeviceItem/DeviceItem";
import { useDispatch, useSelector } from "react-redux";
import { setOpenFilter, setSearchVal } from "../../store/slices/filterSlice";
import { setIsFlag } from "../../store/slices/flagDeviceSlice";
import { setIsOpen } from "../../store/slices/modalFiltrDeviceSlice";
import { fetchProduct } from "../../store/slices/productsSlice";

function CatalogPage() {

  const scrollto = () => {
    window.scrollTo(0, 0);
  };

  const dispatch = useDispatch();
  const products = useSelector((store) => store.products.data);

  const chosenCategoryOrBrand = useSelector(
    (store) => store.filters.chosenCategoryOrBrand
  );
  const crestronProduct = useSelector((store) => store.filters.crestronProduct);
  const siProduct = useSelector((store) => store.filters.siProduct);
  const originProduct = useSelector((store) => store.filters.originProduct);
  const sonosProduct = useSelector((store) => store.filters.sonosProduct);
  const unitechProduct = useSelector((store) => store.filters.unitechProduct);
  const artnovionProduct = useSelector(
    (store) => store.filters.artnovionProduct
  );
  const atelierProduct = useSelector((store) => store.filters.atelierProduct);
  const searchVal = useSelector((store) => store.filters.searchVal);

  const isOpen = useSelector((store) => store.modalDevice.isOpen);


  const openModal = () => {
    dispatch(setIsOpen(true));
  };

  const closeModal = () => {

    const timeout_1 = setTimeout(() => {
      dispatch(setIsFlag(true));
    }, 0);
    const timeout_2 = setTimeout(() => {
      dispatch(setIsFlag(false));
    }, 1000);
    const timeout_3 = setTimeout(() => {
      dispatch(setIsOpen(false));
    }, 700);
    return () => {
      clearTimeout(timeout_1);
      clearTimeout(timeout_2);
      clearTimeout(timeout_3);
    };
  }
  const handleClModal = () => {
    if (isOpen) {
      return closeModal();
    }
    return openModal();
  };

  const fetchData = useMemo(() => () => {
    dispatch(fetchProduct());
  }, [dispatch]);


  useEffect(() => {
    scrollto();

      fetchData();
 

  }, [fetchData]);

  


  const filteredCategory = products.filter(
    (product) =>
      (chosenCategoryOrBrand === "automatization" &&
        product.category === "automatization") ||
      (chosenCategoryOrBrand === "acoustic" &&
        product.category === "acoustic") ||
      (chosenCategoryOrBrand === "cinema" && product.category === "cinema") ||
      (chosenCategoryOrBrand === "furniture" &&
        product.category === "furniture") ||
      (chosenCategoryOrBrand === "crestron" && product.brand === "crestron") ||
      (chosenCategoryOrBrand === "si" && product.brand === "si") ||
      (chosenCategoryOrBrand === "origin" && product.brand === "origin") ||
      (chosenCategoryOrBrand === "sonos" && product.brand === "sonos") ||
      (chosenCategoryOrBrand === "unitech" && product.brand === "unitech") ||
      (chosenCategoryOrBrand === "artnovion" &&
        product.brand === "artnovion") ||
      (chosenCategoryOrBrand === "atelier" && product.brand === "atelier") ||
      (chosenCategoryOrBrand === "furniture" && product.brand === "atelier")
  );

  const filteredBrand = filteredCategory.filter(
    (product) =>
      (!crestronProduct &&
        !siProduct &&
        !originProduct &&
        !sonosProduct &&
        !unitechProduct &&
        !artnovionProduct &&
        !atelierProduct) ||
      (crestronProduct && product.brand === "crestron") ||
      (siProduct && product.brand === "si") ||
      (originProduct && product.brand === "origin") ||
      (sonosProduct && product.brand === "sonos") ||
      (unitechProduct && product.brand === "unitech") ||
      (artnovionProduct && product.brand === "artnovion") ||
      (atelierProduct && product.brand === "atelier")
  );

  // const filterBySearch = products.filter((item) => {
  //   return item.title.toLowerCase().includes(searchVal.toLowerCase());
  // });
  filteredBrand.sort((a, b) => {
    const aIsAvailable = a.status.includes('available');
    const bIsAvailable = b.status.includes('available');
    
    if (aIsAvailable && !bIsAvailable) {
      return -1;
    }
    if (!aIsAvailable && bIsAvailable) {
      return 1;
    }
    return 0;
  });

  const filterBySearch = products.filter((item) => {
    return item.title.toLowerCase().includes(searchVal.toLowerCase());
  });

  filterBySearch.sort((a, b) => {
    const aIsAvailable = a.status.includes('available');
    const bIsAvailable = b.status.includes('available');
    
    if (aIsAvailable && !bIsAvailable) {
      return -1;
    }
    if (!aIsAvailable && bIsAvailable) {
      return 1;
    }
    return 0;
  });



  return (
    <div className={styles.catalogPageRoot}>
      <Helmet>
        <title>Каталог</title>
        <meta
          name="description"
          content="Каталог продуктів. Напишіть нам, щоб отримати детальнішу інформацію. Бренди, представниками яких є компанія Д8: Crestron, Sonos, Screen Innovations, Origin Acoustic, Unitech, ArtNovion, Atelier Luxus"
        />
        <link
          rel="canonical"
          href="https://brands.d8.com.ua/catalog-device-type"
        />
      </Helmet>
      <div className={styles.catalogPage}>
        <div className={styles.catalogpage}>
          <h1 className={styles.catalogpage_head}>Каталог</h1>
          <h2 className={styles.catalogpage_subhead}>
            {chosenCategoryOrBrand === "automatization"
              ? "Автоматизація"
              : chosenCategoryOrBrand === "acoustic"
              ? "Акустика"
              : chosenCategoryOrBrand === "cinema"
              ? "Кінотеатри"
              : chosenCategoryOrBrand === "furniture"
              ? "Фурнітура"
              : chosenCategoryOrBrand === "crestron"
              ? "Crestron"
              : chosenCategoryOrBrand === "si"
              ? "Screen Innovation"
              : chosenCategoryOrBrand === "origin"
              ? "Origin Acoustics"
              : chosenCategoryOrBrand === "sonos"
              ? "Sonos"
              : chosenCategoryOrBrand === "unitech"
              ? "Unitech Systems"
              : chosenCategoryOrBrand === "artnovion"
              ? "Artnovion"
              : "Atelier Luxus"}
          </h2>

          {chosenCategoryOrBrand === "acoustic" ||
          chosenCategoryOrBrand === "cinema" ? (
            <div>
              <button
                className={styles.catalog_fltr_btn}
                onClick={() =>{handleClModal(); dispatch(setOpenFilter(true))}}
              >
                <img
                  className={styles.catalog_fltr_icon}
                  src={filter_btn}
                  alt="Filter Icon"
                />
                Фільтр
              </button>

              <FilterCatalogPhone />

              <FilterCatalogTablet />
            </div>
          ) : (
            <div />
          )}

          <input
            type="text"
            name="finder"
            placeholder="назва продукту"
            onChange={(e) => dispatch(setSearchVal(e.target.value))}
            className={styles.catalogpage_finder}
          />
          <img
            className={styles.catalogpage_search}
            src={search_icon}
            alt="Search Icon"
          />

          {searchVal !== "" ? (
            <div className={styles.catalog_div}>
              {filterBySearch.map((item) => (
                <DeviceItem
                  title={item.title}
                  img={item.img}
                  id={item._id}
                  key={item._id}
                  status={item.status}
                  text={item.describe}
                  img1={item.add_img1}
                  img2={item.add_img2}
                  img3={item.add_img3}
                  img4={item.add_img4}
                  img5={item.add_img5}
                  brand={item.brand}
                />
              ))}
            </div>
          ) : (
            <div className={styles.catalog_div}>
              {filteredBrand.map((item) => (
                <DeviceItem
                  title={item.title}
                  img={item.img}
                  id={item._id}
                  key={item._id}
                  status={item.status}
                  text={item.describe}
                  img1={item.add_img1}
                  img2={item.add_img2}
                  img3={item.add_img3}
                  img4={item.add_img4}
                  img5={item.add_img5}
                  brand={item.brand}
                />
              ))}
            </div>
          )}
        </div>
      </div>
      <div className={styles.catalog_footer}>
        <Footer />
      </div>
    </div>
  );
}

export default CatalogPage;
