import React, { useEffect, useMemo } from "react";
import styles from "./MainInfo.module.scss";
import LastNews from "../LastNews/LastNews";
import OurBrands from "../OurBrands/OurBrands";
import CardContainer from "../CardContainer/CardContainer";
import Footer from "../Footer/Footer";
import Preloader from "../Preloader/index";
import { useDispatch, useSelector } from "react-redux";
import { fetchNews } from "../../store/slices/newsSlice";
import { fetchProduct } from "../../store/slices/productsSlice";

function MainInfo() {

  const dispatch = useDispatch();
  // const news = useSelector((store) => store.news.data);
  const isLoading = useSelector((store) => store.news.isLoading);
  // console.log(news);

  // const fetchData = useCallback(() => {
  //   dispatch(fetchNews());
  // }, [dispatch]);

  // const fetchDataDevice = useCallback(() => {
  //   dispatch(fetchProduct());
  // }, [dispatch]);


  const fetchData = useMemo(() => () => {
    dispatch(fetchNews());
  }, [dispatch]);

  const fetchDataDevice = useMemo(() => () => {
    dispatch(fetchProduct());
  }, [dispatch]);

  useEffect(() => {
    fetchData();
    fetchDataDevice();
  }, [fetchData, fetchDataDevice]);

  return (
    <div className={styles.root}>
      <LastNews />
      <OurBrands />
      {isLoading ? (
        <div className={styles.main_preloader}>
          <Preloader />
        </div>
      ) : (
        <CardContainer id="news" />
      )}
      <div className={styles.main_footer}>
        <Footer id="footer" />
      </div>
    </div>
  );
}

export default MainInfo;
