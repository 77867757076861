import { PureComponent } from "react";
import styles from "./PreloaderSkeleton.module.scss";

class PreloaderSkeleton extends PureComponent {
  render() {
    return (
      <div className={styles.skeletonCatalogRoot}>
      <div className={styles.skeletonCatalogPage}>

        <div className={styles.skeletonCatalogTitle} />
        <div className={styles.skeletonCatalogSearch} />
        
        <div className={styles.skeletonCatalogCategoryTitle} />
        <div className={styles.skeletonCatalogCategory}>
          <div className={styles.skeletonCatalogCategoryFirst}>
            <div className={styles.skeletonCatalogCategoryFirstTitle} />
            <div className={styles.skeletonCatalogArrow} />
          </div>
          <div className={styles.skeletonCatalogCategorySecond}>
            <div className={styles.skeletonCatalogCategorySecondTitle} />
            <div className={styles.skeletonCatalogArrow} />
          </div>
          <div className={styles.skeletonCatalogCategorySecond}>
            <div className={styles.skeletonCatalogCategoryThirdTitle} />
            <div className={styles.skeletonCatalogArrow} />
          </div>
          <div className={styles.skeletonCatalogCategorySecond}>
            <div className={styles.skeletonCatalogCategoryFourthTitle} />
            <div className={styles.skeletonCatalogArrow} />
          </div>
        </div>

        <div className={styles.skeletonCatalogBrandTitle} />
        <div className={styles.skeletonCatalogBrand}>
          <div className={styles.skeletonCatalogBrandFirst}>
            <div className={styles.skeletonCatalogBrandFirstTitle} />
            <div className={styles.skeletonCatalogBrandArrow} />
          </div>
          <div className={styles.skeletonCatalogBrandSecond}>
            <div className={styles.skeletonCatalogBrandSecondTitle} />
            <div className={styles.skeletonCatalogBrandArrow} />
          </div>
          <div className={styles.skeletonCatalogBrandSecond}>
            <div className={styles.skeletonCatalogBrandThirdTitle} />
            <div className={styles.skeletonCatalogBrandArrow} />
          </div>
          <div className={styles.skeletonCatalogBrandSecond}>
            <div className={styles.skeletonCatalogBrandFourthTitle} />
            <div className={styles.skeletonCatalogBrandArrow} />
          </div>
          <div className={styles.skeletonCatalogBrandSecond}>
            <div className={styles.skeletonCatalogBrandFifthTitle} />
            <div className={styles.skeletonCatalogBrandArrow} />
          </div>
          <div className={styles.skeletonCatalogBrandSecond}>
            <div className={styles.skeletonCatalogBrandSixthTitle} />
            <div className={styles.skeletonCatalogBrandArrow} />
          </div>
          <div className={styles.skeletonCatalogBrandSecond}>
            <div className={styles.skeletonCatalogBrandSeventhTitle} />
            <div className={styles.skeletonCatalogBrandArrow} />
          </div>
        </div>
      </div>  
    </div>
    );
  }
}

export default PreloaderSkeleton;
