import { PureComponent } from "react";
import styles from "./Preloader.module.scss";

class Preloader extends PureComponent {
  render() {
    return (
      <div className={styles.lds_ellipsis}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    );
  }
}

export default Preloader;
