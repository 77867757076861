import React, { useEffect }  from "react";
import styles from "./FilterNewsPhone.module.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  setReset,
  setCheckCrestron,
  setCheckSI,
  setCheckOrigin,
  setCheckSonos,
  setCheckUnitech,
  setCheckArtnovion,
  setCheckAtelier,
} from "../../store/slices/filterSliceNews";
import { setIsOpen } from "../../store/slices/modalFiltrNewsSlice.js";


function FilterNewsPhone() {
  const dispatch = useDispatch();

  const checkedCrestron = useSelector(
    (store) => store.filterNews.checkedCrestron
  );
  const checkedSI = useSelector((store) => store.filterNews.checkedSI);
  const checkedOrigin = useSelector((store) => store.filterNews.checkedOrigin);
  const checkedSonos = useSelector((store) => store.filterNews.checkedSonos);
  const checkedUnitech = useSelector(
    (store) => store.filterNews.checkedUnitech
  );
  const checkedArtnovion = useSelector(
    (store) => store.filterNews.checkedArtnovion
  );
  const checkedAtelier = useSelector(
    (store) => store.filterNews.checkedAtelier
  );

  const isOpen = useSelector((store) => store.modalNews.isOpen);
  const isFlag = useSelector((store) => store.flagNews.isFlag);

  const closeModal = () => {
    var elem_1 = document.getElementById("main");
    const timeout_1 = setTimeout(() => {
      elem_1.classList.add(`${styles.root_fade}`);
    }, 0);
    const timeout = setTimeout(() => {
      elem_1.classList.remove(`${styles.root_fade}`);
    }, 1000);

    const timeout_2 = setTimeout(() => {
      dispatch(setIsOpen(false));
    }, 700);

    return () => {
      clearTimeout(timeout);
      clearTimeout(timeout_1);
      clearTimeout(timeout_2);
    };
  };

  useEffect(() => {
    if (isFlag) {
      var elem_1 = document.getElementById("main");

      const timeout_1 = setTimeout(() => {
        elem_1.classList.add(`${styles.root_fade}`);
      }, 0);
      const timeout = setTimeout(() => {
        elem_1.classList.remove(`${styles.root_fade}`);
        console.log(elem_1);
      }, 1000);
      return () => {
        clearTimeout(timeout);
        clearTimeout(timeout_1);
      };
    }
  }, [isFlag]);

  if (!isOpen) {
    return null;
  }

  return (
    <div className={styles.dialog_panel_div} id="main">
      <div className={styles.div_check}>
        <div className={styles.div_set_reset}>
          <button
            type="button"
            onClick={() => dispatch(setReset(false))}
            className={`${
              checkedCrestron ||
              checkedSI ||
              checkedOrigin ||
              checkedSonos ||
              checkedUnitech ||
              checkedArtnovion ||
              checkedAtelier
                ? styles.btn_reset
                : styles.btn_reset_NV
            }`}
          >
            Очистити
          </button>
          <button type="button" className={styles.btn_set} onClick={closeModal}>
            Готово
          </button>
        </div>
        <form className={styles.div_form}>
          <div className={styles.div_line} />
          <div className={styles.div_checkbox}>
            <label className={styles.label_checkbox}>
              <span className={styles.label_text}>Artnovion</span>
              <input
                type="checkbox"
                name="Artnovion"
                checked={checkedArtnovion}
                onChange={() => dispatch(setCheckArtnovion(!checkedArtnovion))}
              />
            </label>

            <label className={styles.label_checkbox}>
              <span className={styles.label_text}>Atelier</span>
              <input
                type="checkbox"
                name="Atelier"
                checked={checkedAtelier}
                onChange={() => dispatch(setCheckAtelier(!checkedAtelier))}
              />
            </label>

            <label className={styles.label_checkbox}>
              <span className={styles.label_text}>Crestron</span>
              <input
                type="checkbox"
                name="Creston"
                checked={checkedCrestron}
                onChange={() => dispatch(setCheckCrestron(!checkedCrestron))}
              />
            </label>

            <label className={styles.label_checkbox}>
              <span className={styles.label_text}>Origin Acoustics</span>
              <input
                type="checkbox"
                name="Origin"
                checked={checkedOrigin}
                onChange={() => dispatch(setCheckOrigin(!checkedOrigin))}
              />
            </label>

            <label className={styles.label_checkbox}>
              <span className={styles.label_text}>SI</span>
              <input
                type="checkbox"
                name="SI"
                checked={checkedSI}
                onChange={() => dispatch(setCheckSI(!checkedSI))}
              />
            </label>

            <label className={styles.label_checkbox}>
              <span className={styles.label_text}>Sonos</span>
              <input
                type="checkbox"
                name="Sonos"
                checked={checkedSonos}
                onChange={() => dispatch(setCheckSonos(!checkedSonos))}
              />
            </label>

            <label className={styles.label_checkbox}>
              <span className={styles.label_text}>Unitech</span>
              <input
                type="checkbox"
                name="Unitech"
                checked={checkedUnitech}
                onChange={() => dispatch(setCheckUnitech(!checkedUnitech))}
              />
            </label>
          </div>
        </form>
      </div>
    </div>
  );
}

export default FilterNewsPhone;
