import React from "react";
import styles from "./CardContainer.module.scss";
import CardItem from "../CardItem/CardItem";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";


const CardContainer = () => {

  const postsAll = useSelector(store => store.news.data);
  const posts = postsAll.slice(0,7);

  // console.log(posts);


  return (
    <div className={styles.cardContainer} id="news">
      <ul className={styles.list}>
        {posts.length &&
          posts.splice(0,6).map(({ _id, title, text, category, flag, img, data_post, img2, brand }) => (
            <li key={_id}>
              <CardItem
                key={_id}
                id={_id}
                text={text}
                title={title}
                category={category}
                flag={flag}
                img={img}
                dataPost={data_post}
                img2={img2}
                brand={brand}
              />
            </li>
          ))}
      </ul>
      <NavLink
        to={"/all-news"}
        style={({ isActive, isPending }) => {
          return {
            fontWeight: isActive ? "bold" : "",
            color: isPending ? "darkgrey" : "black",
            textDecoration: "none",
          };
        }}
      >
        <button className={styles.cardContainer_btn}>Більше новин</button>
      </NavLink>
    </div>
  );
};

export default CardContainer;
