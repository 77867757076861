import React from 'react';
import styles from "./FilterNewsTablet.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { setReset, setCheckCrestron, setCheckSI, setCheckOrigin, setCheckSonos, setCheckUnitech, setCheckArtnovion, setCheckAtelier} from "../../store/slices/filterSliceNews";


function FilterNewsTablet() {

  const dispatch = useDispatch();
  const checkedCrestron = useSelector((store) => store.filterNews.checkedCrestron);
  const checkedSI = useSelector((store) => store.filterNews.checkedSI);
  const checkedOrigin = useSelector((store) => store.filterNews.checkedOrigin);
  const checkedSonos = useSelector((store) => store.filterNews.checkedSonos);
  const checkedUnitech = useSelector((store) => store.filterNews.checkedUnitech);
  const checkedArtnovion = useSelector(
    (store) => store.filterNews.checkedArtnovion
  );
  const checkedAtelier = useSelector((store) => store.filterNews.checkedAtelier);
      
      return ( 
        <form className={styles.tablet_div_form}>
          <div className={styles.tablet_div}>
            <label className={styles.tablet_label_checkbox}>
              <span className={styles.tablet_label_text}>Artnovion</span>
              <input 
                type="checkbox"
                name="Artnovion"
                checked={checkedArtnovion} 
                onChange={() => dispatch(setCheckArtnovion(!checkedArtnovion))} />
            </label>
                                    
            <label className={styles.tablet_label_checkbox}>
              <span className={styles.tablet_label_text}>Atelier</span>
              <input 
                type="checkbox"
                name="Atelier" 
                checked={checkedAtelier}
                onChange={() => dispatch(setCheckAtelier(!checkedAtelier))} />
            </label>

            <label className={styles.tablet_label_checkbox}>
              <span className={styles.tablet_label_text}>Crestron</span>
              <input 
                type="checkbox"
                name="Creston"
                checked={checkedCrestron}                                     
                onChange={() => dispatch(setCheckCrestron(!checkedCrestron))} />
            </label>

            <label className={styles.tablet_label_checkbox}>
              <span className={styles.tablet_label_text}>Origin Acoustics</span>
              <input 
                type="checkbox"
                name="Origin"
                checked={checkedOrigin}                                     
                onChange={() => dispatch(setCheckOrigin(!checkedOrigin))} />
            </label>

            <label className={styles.tablet_label_checkbox}>
              <span className={styles.tablet_label_text}>SI</span>
              <input 
                type="checkbox"
                name="SI"
                checked={checkedSI}                                     
                onChange={() => dispatch(setCheckSI(!checkedSI))} />
            </label>

            <label className={styles.tablet_label_checkbox}>
              <span className={styles.tablet_label_text}>Sonos</span>
              <input 
                type="checkbox"
                name="Sonos"
                checked={checkedSonos}                                     
                onChange={() => dispatch(setCheckSonos(!checkedSonos))} />
            </label>

            <label className={styles.tablet_label_checkbox}>
              <span className={styles.tablet_label_text}>Unitech</span>
              <input 
                type="checkbox"
                name="Unitech"
                checked={checkedUnitech}                                     
                onChange={() => dispatch(setCheckUnitech(!checkedUnitech))} />
            </label>
            <button 
          type="button" 
          onClick={() => dispatch(setReset(false))} 
          className={`${checkedCrestron || checkedSI || checkedOrigin || checkedSonos || checkedUnitech || checkedArtnovion || checkedAtelier ? styles.tablet_btn_reset : styles.tablet_btn_reset_NV}`}>
          Очистити
        </button> 
          </div>
        
      </form>
    );

  
}

export default FilterNewsTablet;
