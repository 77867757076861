import { createSlice } from "@reduxjs/toolkit"; // with immer and produce
import getAllNews from "../../api/getAllNews";

const newsSlice = createSlice({
    name: 'news',
    initialState: {
        data: [],
        isLoading: false,
    },
    reducers: {
        addNews: (state, action) => {
            state.data = action.payload;
        },

        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        }
    }
});

const { addNews, setIsLoading } = newsSlice.actions;

const fetchNews = () => async (dispatch) => {
    dispatch(setIsLoading(true));
    const news = await getAllNews();

    dispatch(addNews(news));
    dispatch(setIsLoading(false));
}

export {fetchNews, addNews, setIsLoading};

export default newsSlice.reducer;