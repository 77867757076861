import React, { useEffect } from "react";
import styles from "./CardItem.module.scss";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  setIdNews,
  setIsTitle,
  setIsText,
  setIsData,
  setIsImg,
  setIsImg2,
  setIsBrand,
} from "../../store/slices/idNewsSlice";

const CardItem = (props) => {
  const { title, text, img, id, dataPost, img2, brand } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    const newDescribe = document.getElementById(`${id}`);
    newDescribe.innerHTML = text;

    const newDate = document.getElementById(`${title}`);
    newDate.innerHTML = dataPost;
  }, [text, dataPost, id, title]);

  return (
    <>
      <NavLink
        to={"/all-news/current-news"}
        style={({ isActive, isPending }) => {
          return {
            fontWeight: isActive ? "bold" : "",
            color: isPending ? "darkgrey" : "black",
            textDecoration: "none",
          };
        }}
      >
        <div
          className={styles.card}
          onClick={() => {
            dispatch(setIdNews({ id }));
            dispatch(setIsTitle({ title }));
            dispatch(setIsText({ text }));
            dispatch(setIsData({ dataPost }));
            dispatch(setIsImg({ img }));
            dispatch(setIsImg2({ img2 }));
            dispatch(setIsBrand({ brand }));
          }}
        >
          <img src={img} alt={title} className={styles.card_img} />
          <span className={styles.title}>{title}</span>
          <span className={styles.description} id={`${id}`}></span>
          <span className={styles.dataPost} id={`${title}`}></span>
        </div>
      </NavLink>
    </>
  );
};

CardItem.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  img: PropTypes.string,
};
CardItem.defaultProps = {
  title: "",
  text: "",
  img: "",
};

export default CardItem;
