import { createSlice } from "@reduxjs/toolkit"; // with immer and produce

const flagDeviceSlice = createSlice({
    name: 'flagDevice',
    initialState: {
        isFlag: false
    },
    reducers: {
        setIsFlag: (state, action) => {
            state.isFlag = action.payload;
        },

    }
});

export const { setIsFlag } = flagDeviceSlice.actions;

export default flagDeviceSlice.reducer;