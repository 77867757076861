const getAllProducts = async () => {
  const result1 = await fetch(
    "https://brands-wp.d8.com.ua/wp-json/wp/v2/posts?page=1&per_page=100"
  ).then((res) => res.json());
  const result2 = await fetch(
    "https://brands-wp.d8.com.ua/wp-json/wp/v2/posts?page=2&per_page=100"
  ).then((res) => res.json());
  const result3 = await fetch(
    "https://brands-wp.d8.com.ua/wp-json/wp/v2/posts?page=3&per_page=100"
  ).then((res) => res.json());
  const result4 = await fetch(
    "https://brands-wp.d8.com.ua/wp-json/wp/v2/posts?page=4&per_page=100"
  ).then((res) => res.json());
  const result = result1.concat(result2).concat(result3).concat(result4);
  // console.log(result);

  return result
    .map((item) => {
      const container = {};

      if (item.template === "devices") {
        container["_id"] = item.id;
        container["title"] = item.title.rendered;
        container["describe"] = item.content.rendered;
        container["img"] = item.yoast_head_json.og_image[0].url;
        container["category_check"] = item.template;

        container["add_img1"] = item.acf.image1;
        container["add_img2"] = item.acf.image2;
        container["add_img3"] = item.acf.image3;
        container["add_img4"] = item.acf.image4;
        container["add_img5"] = item.acf.image5;

        if (item.tags[0] === 19) {
          container["color"] = "black";
        } else if (item.tags[0] === 20) {
          container["color"] = "white";
        } else if (item.tags[0] === 21) {
          container["color"] = "universe";
        }

        if (item.categories[0] === 15) {
          container["category"] = "automatization";
        } else if (item.categories[0] === 16) {
          container["category"] = "acoustic";
        } else if (item.categories[1] === 17) {
          container["category"] = "cinema";
        } else if (item.categories[3] === 18) {
          container["category"] = "furniture";
        } else if (item.categories[0] === 23 && item.categories[2] === 17 && item.categories[4] === 25) {
          container["brand"] = "artnovion";
          container["category"] = "cinema";
          container["status"] = "available";
        }else if (item.categories[0] === 23 && item.categories[2] === 17 && item.categories[4] === 26) {
          container["brand"] = "artnovion";
          container["category"] = "cinema";
          container["status"] = "order";
        }

        if (item.categories[2] === 8 && item.categories[4] === 25) {
          container["brand"] = "crestron";
          container["status"] = "available";
        }else if (item.categories[2] === 8 && item.categories[4] === 26) {
            container["brand"] = "crestron";
            container["status"] = "order";
        } else if (item.categories[3] === 25 && item.categories[4] === 11) {
          container["brand"] = "origin";
          container["status"] = "available";
        } else if (item.categories[3] === 11 && item.categories[4] === 26) {
          container["brand"] = "origin";
          container["status"] = "order";
        } else if (item.categories[3] === 10  && item.categories[4] === 26) {
          container["brand"] = "si";
          container["status"] = "order";
        } else if (item.categories[4] === 10  && item.categories[3] === 25) {
          container["brand"] = "si";
          container["status"] = "available";
        } else if (item.categories[0] === 13 && item.categories[4] === 25) {
          container["brand"] = "atelier";
          container["status"] = "available";
        } else if (item.categories[0] === 13 && item.categories[4] === 26) {
          container["brand"] = "atelier";
          container["status"] = "order";
        } else if (item.categories[3] === 9 && item.categories[4] === 26) {
          container["brand"] = "sonos";
          container["status"] = "available";
        } else if (item.categories[4] === 9 && item.categories[3] === 25) {
          container["brand"] = "sonos";
          container["status"] = "order";
        } else if (item.categories[4] === 12 && item.categories[3] === 25) {
          container["brand"] = "unitech";
          container["status"] = "available";
        }else if (item.categories[4] === 12 && item.categories[3] === 26) {
          container["brand"] = "unitech";
          container["status"] = "order";
        }


        // console.log(container);
      }

      return container;
    })
    .filter((item) => item.category_check === "devices");
};

export default getAllProducts;

// https://brands-wp.d8.com.ua/wp-json/wp/v2/posts?per_page=100&page=1
//https://brands-wp.d8.com.ua/wp-json/wp/v2/posts?per_page=100&page=2
//2 отримані масиви склеяти між собою, якщо більше 100 постів, і тільки тоді дані спільного масиву передавати в програму
