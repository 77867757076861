import { PureComponent } from "react";
import styles from "./Crestron.module.scss";
import Footer from "../../components/Footer/Footer";
import crestron_1 from "../../img/crestron_1.png";
import crestron_2 from "../../img/crestron_2.png";
import crestron_3 from "../../img/crestron_3.png";
import crestr_11 from "../../img/crestr_1-1.png";
import crestr_12 from "../../img/crestr_1-2.png";
import crestr_13 from "../../img/crestr_1-3.png";
import crestr_14 from "../../img/crestr_1-4.png";
import crestr_15 from "../../img/crestr_1-5.png";
import crestr_16 from "../../img/crestr_1-6.png";
import { Helmet } from "react-helmet";
import OurBrandsCatalog from "../../components/OurBrandsCatalog/OurBrandsCatalog";

class Crestron extends PureComponent {
  scrollto = () => {
    window.scrollTo(0, 0);
  };

  componentDidMount() {

    this.scrollto();

  }

  render() {
    return (
      <div className={styles.crestronRoot}>
        <Helmet>
          <title>Crestron</title>
          <meta
            name="description"
            content="Компанія, яка є лідером у створенні інноваційних технологій, які усувають бар'єри для зв'язку, співпраці, комунікації, комфорту та контролю практично в кожному значущому аспекті нашого професійного та особистого життя.  Crestron встановлює стандарти для інтелектуальних відеоконференцій, розповсюдження цифрового контенту, систем розумного будинку, а також технологій контролю та управління. Crestron"
          />
          <link rel="canonical" href="https://brands.d8.com.ua/crestron" />
        </Helmet>
        <div className={styles.crestron}>
          <h1 className={styles.crestron_chapter}>Про Crestron</h1>
          <div className={styles.crestron_block}>
            <div className={styles.crestron_img_1}>
              <img src={crestron_1} alt="d8 brands crestron logo"></img>
            </div>
            <div className={styles.crestron_text_1}>
              Компанія з США, яка з 1972 року є лідером у створенні інноваційних
              технологій, які усувають бар'єри для зв'язку, співпраці,
              комунікації, комфорту та контролю практично в кожному значущому
              аспекті нашого професійного та особистого життя. Створені для
              того, щоб бути простими, надійними, безпечними та зручними у
              використанні, Crestron встановлює стандарти для інтелектуальних
              відеоконференцій, розповсюдження цифрового контенту, систем
              "розумного будинку", а також технологій контролю та управління.
              Рішення, які дають можливість людям по всьому світу робити більше,
              дізнаватися більше, насолоджуватися більше і досягати більше.
            </div>
            <div className={styles.crestron_whereUse}>
              <h3 className={styles.whereUse_title}>Де використовується</h3>
              <div className={styles.whereUse_text}>
                Crestron можна знайти в залах засідань найбільших світових
                компаній, лекційних аудиторіях престижних університетів, на
                розкішних супер яхтах і в деяких по-справжньому розкішних
                будинках. Він встановлений в Колумбійському Конгресі, Пентагоні,
                NASA та Білому домі, в залах судових засідань та аудиторіях, і
                навіть на спортивних стадіонах вищої ліги.
              </div>
            </div>
            <div className={styles.crestron_partners}>
              <img
                src={crestron_2}
                alt="crestron partners puma usc Mariott greensbord williams"
              ></img>
            </div>
            <div className={styles.crestron_img_3}>
              <img
                src={crestron_3}
                alt="d8 brands crestron touchpanel tsr"
              ></img>
            </div>
            <div className={styles.crestron_whatMoves}>
              <h3 className={styles.whatMoves_title}>Що нами рухає</h3>
              <div className={styles.whatMoves_text}>
                Для Creston досвід - це все. Десятиліття досвіду, який ми
                привносимо в кожне рішення. Ми хочемо, щоб кожен, хто
                покладається на нас вдома, на роботі, в школі чи під час
                будь-чого, отримував найкращий досвід. Успішний досвід, якого ми
                прагнемо для наших дилерів, партнерів, інсталяторів та наших
                співробітників. Це наша пристрасть. Ось чому ми інвестуємо в
                навчання. Ось чому ми постійно прислухаємося до клієнтів.
                Постійно вдосконалюємо наші рішення. Ось чому наша команда True
                Blue підтримує вас на кожному етапі, від проектування до
                встановлення та усунення несправностей. Все тому, що ваш досвід
                має для нас велике значення.
              </div>
            </div>
            <div className={styles.crestron_whatCreate}>
              <h3 className={styles.whatCreate_title}>Що ми створюємо</h3>
              <div className={styles.whatCreate_text}>
                Від комерційних до житлових приміщень, від освіти до розваг -
                технології Crestron торкаються кожного куточка нашого
                суспільства. Створений для задоволення потреб сьогоднішнього дня
                і готовий до вимог завтрашнього. Прості, масштабовані та
                безпечні рішення.
              </div>
            </div>
            <div className={styles.crestron_CreateBlock}>
              <div className={styles.create_Commercial}>
                <img
                  className={styles.crestron_img_1_create}
                  src={crestr_11}
                  alt="crestron office mercury screen learn"
                ></img>

                <div className={styles.create_CommercialText}>
                  <span className={styles.create_CommercialTitle}>
                    Комерційні простори
                  </span>
                  <div className={styles.create_CommercialDescribe}>
                    Рішення, які допомагають організаціям будь-якого типу
                    працювати краще та ефективніше в сучасному гібридному світі
                  </div>
                </div>
              </div>
              <div className={styles.create_Resident}>
                <img
                  className={styles.crestron_img_1_Resident}
                  src={crestr_12}
                  alt="crestron house home automation"
                ></img>

                <div className={styles.create_ResidentText}>
                  <span className={styles.create_ResidentTitle}>
                    Житлові простори
                  </span>
                  <div className={styles.create_ResidentDescribe}>
                    З Crestron все складне стає простим. Все, що ви хочете, де б
                    ви не знаходилися, знаходиться на відстані одного дотику
                  </div>
                </div>
              </div>
              <div className={styles.create_MDU}>
                <img
                  className={styles.crestron_img_1_MDU}
                  src={crestr_13}
                  alt="crestron mdu automation penthouse"
                ></img>

                <div className={styles.create_MDUText}>
                  <span className={styles.create_MDUTitle}>MDU</span>
                  <div className={styles.create_MDUDescribe}>
                    Всі рішення на одній зручній платформі. Чудовий досвід, який
                    підвищує цінність нерухомості з кожним днем
                  </div>
                </div>
              </div>
              <div className={styles.create_Hotel}>
                <img
                  className={styles.crestron_img_1_Hotel}
                  src={crestr_14}
                  alt="crestron hotel booking rooms"
                ></img>
                <div className={styles.create_HotelText}>
                  <span className={styles.create_HotelTitle}>
                    Готельні простори
                  </span>
                  <div className={styles.create_HotelDescribe}>
                    Автоматизовані кімнати, які перетворюють нових відвідувачів
                    на постійних гостей
                  </div>
                </div>
              </div>
              <div className={styles.create_Teach}>
                <img
                  className={styles.crestron_img_1_Teach}
                  src={crestr_15}
                  alt="university school teach learn presentation"
                ></img>
                <div className={styles.create_TeachText}>
                  <span className={styles.create_TeachTitle}>
                    Освітні простори
                  </span>
                  <div className={styles.create_TeachDescribe}>
                    Crestron може надати технологічне навчання, необхідне вам
                    сьогодні, а також підготувати вас до нових методів навчання
                    завтрашнього дня
                  </div>
                </div>
              </div>
              <div className={styles.create_Government}>
                <img
                  className={styles.crestron_img_1_Government}
                  src={crestr_16}
                  alt="Government automation cabinet control"
                ></img>
                <div className={styles.create_GovernmentText}>
                  <span className={styles.create_GovernmentTitle}>
                    Урядові установи
                  </span>
                  <div className={styles.create_GovernmentDescribe}>
                    Кожен рід військ, а також безліч урядових установ по всьому
                    світу покладаються на технологію Crestron
                  </div>
                </div>
              </div>
            </div>
          </div>
          <OurBrandsCatalog />
        </div>
        <Footer />
      </div>
    );
  }
}

export default Crestron;
