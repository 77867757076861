import styles from "./Header.module.scss";
import logo from "../../img/HeaderLogo.png";
import YouTube from "../../img/footer_youtube_button.png";
import Instagram from "../../img/footer_instagram_button.png";
import Facebook from "../../img/footer_facebook_button.png";
import Linkedin from "../../img/footer_linkedin_button.png";
import TikTok from "../../img/footer_tiktok_button.png";
import { NavLink } from "react-router-dom";
// import { HashLink as Link } from "react-router-hash-link";
import { useDispatch, useSelector } from "react-redux";
import { setIsOpen } from "../../store/slices/modalSlice";
import { setIsFlag } from "../../store/slices/flagSlice";
import { setIsFlagHead } from "../../store/slices/flagHeadSlice";

const Header = () => {
  const isOpen = useSelector((store) => store.modal.isOpen);
  const isFlagHead = useSelector((store) => store.flagHead.isFlagHead);
  const dispatch = useDispatch();

  const openModal = () => {
    dispatch(setIsFlagHead(true));
    dispatch(setIsOpen(true));
  };

  const closeModal = () => {
    dispatch(setIsFlagHead(false));
    const timeout_1 = setTimeout(() => {
      dispatch(setIsFlag(true));
    }, 0);
    const timeout_2 = setTimeout(() => {
      dispatch(setIsFlag(false));
    }, 1000);
    const timeout_3 = setTimeout(() => {
      dispatch(setIsOpen(false));
    }, 700);
    return () => {
      clearTimeout(timeout_1);
      clearTimeout(timeout_2);
      clearTimeout(timeout_3);
    };
  };

  const handleClModal = () => {
    if (isOpen) {
      return closeModal();
    }
    return openModal();
  };

  return (
    <header className={styles.root}>
      <div className={styles.headerLine}>
        <div className={styles.headerMenu}>
          <div className={styles.menuBtn} onClick={handleClModal}>
            <span
              className={
                isFlagHead
                  ? `${styles.lineFirst_rotate}`
                  : `${styles.lineFirst}`
              }
            ></span>
            <span
              className={
                isFlagHead
                  ? `${styles.lineSecond_rotate}`
                  : `${styles.lineSecond}`
              }
            ></span>
            <span
              className={
                isFlagHead
                  ? `${styles.lineThird_rotate}`
                  : `${styles.lineThird}`
              }
            ></span>
          </div>
          <NavLink
            to={"/"}
            style={({ isActive, isPending }) => {
              return {
                fontWeight: isActive ? "bold" : "",
                color: isPending ? "darkgrey" : "black",
                textDecoration: "none",
              };
            }}
          >
            <img
              className={styles.headerLogo}
              src={logo}
              alt="d8 brands logo"
            ></img>
          </NavLink>
          <NavLink
            to={"/all-news"}
            style={({ isActive, isPending }) => {
              return {
                fontWeight: isActive ? "bold" : "",
                color: isPending ? "darkgrey" : "black",
                textDecoration: "none",
              };
            }}
          >
            <span className={styles.headerMenu_news}>новини</span>
          </NavLink>
          <NavLink
            to={"/brands"}
            style={({ isActive, isPending }) => {
              return {
                fontWeight: isActive ? "bold" : "",
                color: isPending ? "darkgrey" : "black",
                textDecoration: "none",
              };
            }}
          >
            <span className={styles.headerMenu_brands}>бренди</span>
          </NavLink>
          <NavLink
            to={"/catalog"}
            style={({ isActive, isPending }) => {
              return {
                fontWeight: isActive ? "bold" : "",
                color: isPending ? "darkgrey" : "black",
                textDecoration: "none",
              };
            }}
          >
            <span className={styles.headerMenu_catalog}>каталог</span>
          </NavLink>
          <NavLink
            to={"/contacts"}
            style={({ isActive, isPending }) => {
              return {
                fontWeight: isActive ? "bold" : "",
                color: isPending ? "darkgrey" : "black",
                textDecoration: "none",
              };
            }}
          >
            <span className={styles.headerMenu_contacts}>контакти</span>
          </NavLink>

          <div className={styles.headerMenu_socIcon}>
            <NavLink
              to="https://www.instagram.com/d8_engineering/"
              target="_blank"
            >
              <img
                src={Instagram}
                alt="Instagram Icon"
                className={styles.headerMenu_instagram}
              ></img>
            </NavLink>

            <NavLink to="https://www.facebook.com/d8engineer" target="_blank">
              <img
                src={Facebook}
                alt="Facebook Icon"
                className={styles.headerMenu_facebook}
              ></img>
            </NavLink>
            <NavLink
              to="https://www.youtube.com/@D8Engineering"
              target="_blank"
            >
              <img
                src={YouTube}
                alt="Youtube Icon"
                className={styles.headerMenu_youtube}
              ></img>
            </NavLink>
            <NavLink
              to="https://www.linkedin.com/company/d8-engineering"
              target="_blank"
            >
              <img
                src={Linkedin}
                alt="Linkedin Icon"
                className={styles.headerMenu_linkedin}
              ></img>
            </NavLink>
            <NavLink
              to="https://www.tiktok.com/@d8.engineering"
              target="_blank"
            >
              <img
                src={TikTok}
                alt="TikTok Icon"
                className={styles.headerMenu_tiktok}
              ></img>
            </NavLink>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
