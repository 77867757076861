import React, { useEffect } from "react";
import styles from "./NewsItemPage.module.scss";
import { Helmet } from "react-helmet";
import Footer from "../../components/Footer/Footer";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

function NewsItemPage() {
  const scrollto = () => {
    window.scrollTo(0, 0);
  };

  const renderId = useSelector((store) => store.idNews.isNews);
  const renderTitle = useSelector((store) => store.idNews.isTitle);
  const renderText = useSelector((store) => store.idNews.isText);
  const renderData = useSelector((store) => store.idNews.isData);
  const renderImg = useSelector((store) => store.idNews.isImg);
  const renderImg2 = useSelector((store) => store.idNews.isImg2);
  const renderImg3 = useSelector((store) => store.idNews.isImg3);
  const renderBrand = useSelector((store) => store.idNews.isBrand);

  useEffect(() => {
    scrollto();

    const newDescribe = document.getElementById(`${renderId.id}`);
    newDescribe.innerHTML = renderText.text;

    const newDate = document.getElementById(`${renderTitle.title}`);
    newDate.innerHTML = renderData.dataPost;
  }, [renderData.dataPost, renderId.id, renderText.text, renderTitle.title]);

  return (
    <div className={styles.newsItemPageRoot}>
      <Helmet>
        <title>{renderTitle.title}</title>
        <meta name="description" content={`${renderTitle.title}`} />
        {/* <link rel="canonical" href="https://brands.d8.com.ua/all-news" /> */}
      </Helmet>
      <div className={styles.newsItemPage_wrap}>
        <div className={styles.news}>
          <h1 className={styles.news_header}>{renderTitle.title}</h1>
          {renderImg.img.length > 0 ? (
            <img
              src={renderImg.img}
              className={styles.news_img_1}
              alt={renderTitle.title}
            />
          ) : (
            ""
          )}
          <h1 className={styles.news_title_1}>{renderTitle.title}</h1>
          <p className={styles.news_describe_1} id={`${renderId.id}`}></p>

          {renderImg2.img2.length > 0 ? (
            <img
              src={renderImg2.img2}
              className={styles.news_img_2}
              alt={renderBrand.brand}
            />
          ) : (
            ""
          )}

          {renderImg3.img3.length > 0 ? (
            <img
              src={renderImg3.img3}
              className={styles.news_img_2}
              alt={renderBrand.brand}
            />
          ) : (
            ""
          )}

          <p className={styles.newspage_date} id={`${renderTitle.title}`}></p>
      
        </div>
      </div>

      <NavLink
        to={"/all-news"}
        style={({ isActive, isPending }) => {
          return {
            fontWeight: isActive ? "bold" : "",
            color: isPending ? "darkgrey" : "black",
            textDecoration: "none",
          };
        }}
      >
        <button className={styles.newsPage_btn}>Інші новини</button>
      </NavLink>
      <div className={styles.newsItem_footer}>
        <Footer />
      </div>
    </div>
  );
}

export default NewsItemPage;
