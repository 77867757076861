import { Routes, Route} from 'react-router-dom';
import MainInfo from "./components/MainInfo/MainInfo";
import Crestron from "./pages/Crestron/Crestron";
import OurBrandsPage from './pages/OurBrandsPage/OurBrandsPage';
import NotFoundPage from './pages/NotFoundPage/NotFoundPage';
import ScreenInnovationPage from './pages/ScreenInnovationPage/ScreenInnovationPage';
import OriginAcousticsPage from './pages/OriginAcousticsPage/OriginAcousticsPage';
import SonosPage from './pages/SonosPage/SonosPage';
import UnitechSystemsPage from './pages/UnitechSystemsPage/UnitechSystemsPage';
import ArtnovionPage from './pages/ArtnovionPage/ArtnovionPage';
import AtelierLuxusPage from './pages/AtelierLuxusPage/AtelierLuxusPage';
import ContactsPage from './pages/ContactsPage/ContactsPage';
import CatalogFilterMainPage from './pages/CatalogFilterMainPage/CatalogFilterMainPage';
import CatalogPage from './pages/CatalogPage/CatalogPage';
import NewsPage from './pages/NewsPage/NewsPage';
import NewsItemPage from './pages/NewsItemPage/NewsItemPage';
import DeviceItemPage from './pages/DeviceItemPage/DeviceItemPage';

const AppRoutes = () => {

    return (
        <Routes>
            <Route path='/' element={<MainInfo/>}/>
            <Route path='/main' element={<MainInfo/>}/>
            <Route path='/crestron' element={<Crestron/>}/>
            <Route path='/screen-innovations' element={<ScreenInnovationPage/>}/>
            <Route path='/origin-acoustics' element={<OriginAcousticsPage/>}/>
            <Route path='/unitech' element={<UnitechSystemsPage/>}/>
            <Route path='/artnovion' element={<ArtnovionPage/>}/>
            <Route path='/atelier-luxus' element={<AtelierLuxusPage/>}/>
            <Route path='/sonos' element={<SonosPage/>}/>
            <Route path='/brands' element={<OurBrandsPage/>}/>
            <Route path='/contacts' element={<ContactsPage/>}/>
            <Route path='/catalog' element={<CatalogFilterMainPage/>}/>
            <Route path='/catalog-chosen-category' element={<CatalogPage/>}/>
            <Route path='/all-news' element={<NewsPage />}/>
            <Route path='/404' element={<NotFoundPage />}/>
            <Route path='*' element={<NotFoundPage />}/>
            <Route path='/all-news/current-news' element={<NewsItemPage />}/>
            <Route path='/catalog/chosen-device' element={<DeviceItemPage />}/>
            </Routes>
    )
}
export default AppRoutes;