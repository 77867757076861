import React, {useEffect} from "react";
import styles from "./ContactsPage.module.scss";
import { Helmet } from "react-helmet";
import icon_mail from "../../img/email_icon.png";
import contacts_1 from "../../img/instagram_icon.png";
import contacts_2 from "../../img/facebook_icon.png";
import contacts_3 from "../../img/youtube_icon.png";
import contacts_4 from "../../img/linkedin_icon.png";
import contacts_5 from "../../img/tiktok_icon.png";

function ContactsPage() {

  const scrollto = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    scrollto();
  }, []);

    return (
      <div className={styles.contactsPageRoot}>
        <Helmet>
          <title>Наші контакти</title>
          <meta
            name="description"
            content="Наші контакти. Напишіть нам, щоб отримати детальнішу інформацію. Бренди, представниками яких є компанія Д8: Crestron, Sonos, Screen Innovations, Origin Acoustic, Unitech, ArtNovion, Atelier Luxus"
          />
          <link rel="canonical" href="https://brands.d8.com.ua/contacts" />
        </Helmet>
        <div className={styles.contactsPage}>
          <div className={styles.contacts}>
            <h1 className={styles.contacts_title}>Контакти D8 Engineering</h1>
            <p className={styles.contacts_text}>
              Зв'яжіться з нами, щоб почати співпрацю
            </p>

            <a
              href={"mailto:brands@d8.com.ua"}
              className={styles.contacts_mail}
            >
              <img
                src={icon_mail}
                alt="Mail Icon"
                className={styles.contacts_icon_mail}
              />
              <p className={styles.contacts_text_mail}>brands@d8.com.ua</p>
            </a>

            <div className={styles.contacts_line} />

            <div className={styles.contacts_icon_div}>
              <a href={"https://www.instagram.com/d8_engineering/"}>
                <img
                  className={styles.contacts_icons}
                  src={contacts_1}
                  alt="Instagram"
                />
              </a>
              <a href={"https://www.facebook.com/d8engineer/"}>
                <img
                  className={styles.contacts_icons}
                  src={contacts_2}
                  alt="Facebook"
                />
              </a>
              <a href={"https://www.youtube.com/@D8Engineering"}>
                <img
                  className={styles.contacts_icons}
                  src={contacts_3}
                  alt="Youtube"
                />
              </a>
              <a href={"https://ua.linkedin.com/company/d8-engineering"}>
                <img
                  className={styles.contacts_icons}
                  src={contacts_4}
                  alt="LinkedIn"
                />
              </a>
              <a href={"https://www.tiktok.com/@d8.engineering"}>
                <img
                  className={styles.contacts_icons}
                  src={contacts_5}
                  alt="Tiktok"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    );
}

export default ContactsPage;
