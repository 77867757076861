import React from "react";
import App from "./App";
import { hydrateRoot, createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import store from "./store";

function AppWithCallbackAfterRender() {
  return (
    <Provider store={store}>
        <App />
    </Provider>
  );
}

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  const root = hydrateRoot(
    rootElement,
    <Provider store={store}>
        <App />
    </Provider>
  );
  root.render(
    <Provider store={store}>
        <App />
    </Provider>
  );
} else {
  const root = createRoot(rootElement);
  root.render(<AppWithCallbackAfterRender />);
}
