const getAllNews = async () => {
  const result1 = await fetch(
    "https://brands-wp.d8.com.ua/wp-json/wp/v2/posts?page=1&per_page=100"
  ).then((res) => res.json());
  const result2 = await fetch(
    "https://brands-wp.d8.com.ua/wp-json/wp/v2/posts?page=2&per_page=100"
  ).then((res) => res.json());
  const result3 = await fetch(
    "https://brands-wp.d8.com.ua/wp-json/wp/v2/posts?page=3&per_page=100"
  ).then((res) => res.json());
  const result4 = await fetch(
    "https://brands-wp.d8.com.ua/wp-json/wp/v2/posts?page=4&per_page=100"
  ).then((res) => res.json());
  const result = result1.concat(result2).concat(result3).concat(result4);
  // console.log(result);


  return result.map((item) => {
    const container = {};
    if (item.template === "news") {
      if (Object.keys(item).length !== 0) {
        container["_id"] = item.id;
        container["title"] = item.title.rendered;
        container["text"] = item.content.rendered;
        container["img"] = item.yoast_head_json.og_image[0].url;
        container["category"] = item.template;
        container["data_post"] = item.excerpt.rendered;

        if (item.categories[0] === 8) {
          container["brand"] = "crestron";
        } else if (item.categories[1] === 11) {
          container["brand"] = "origin";
        } else if (item.categories[1] === 10) {
          container["brand"] = "si";
        } else if (item.categories[0] === 13) {
          container["brand"] = "atelier";
        } else if (item.categories[1] === 9) {
          container["brand"] = "sonos";
        } else if (item.categories[1] === 12) {
          container["brand"] = "unitech";
        }

        container["img2"] = item.acf.image1;
        container["img3"] = item.acf.image3;

        // console.log(container);
      }
    }
    return container;
  }).filter((item) => item.category === "news");
};

export default getAllNews;

// https://brands-wp.d8.com.ua/wp-json/wp/v2/posts
//./news.json

// https://brands-wp.d8.com.ua/wp-json/wp/v2/posts?page=2&per_page=100
// const array3 = array1.concat(array2);

// https://brands-wp.d8.com.ua/wp-json/wp/v2/posts/1458
// for one posts