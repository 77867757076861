import React, { useEffect } from "react";
import styles from "./DeviceItemPage.module.scss";
import { Helmet } from "react-helmet";
import Footer from "../../components/Footer/Footer";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import DeviceItemPagePart from "./DeviceItemPagePart";

function DeviceItemPage() {
  const scrollto = () => {
    window.scrollTo(0, 0);
  };

  const products = useSelector((store) => store.products.data);
  const renderId = useSelector((store) => store.idDevice.idDevice);
  const renderTitle = useSelector((store) => store.idDevice.isTitle);
  const renderText = useSelector((store) => store.idDevice.isText);
  const renderImg = useSelector((store) => store.idDevice.isImg);
  const renderImg1 = useSelector((store) => store.idDevice.isImg1);
  const renderImg2 = useSelector((store) => store.idDevice.isImg2);
  const renderImg3 = useSelector((store) => store.idDevice.isImg3);
  const renderImg4 = useSelector((store) => store.idDevice.isImg4);
  const renderImg5 = useSelector((store) => store.idDevice.isImg5);
  const renderBrand = useSelector((store) => store.idDevice.isBrand);
  const renderStatus = useSelector((store) => store.idDevice.isStatus);

  const renderArr = [renderImg, renderImg1, renderImg2, renderImg3, renderImg4, renderImg5];



  useEffect(() => {
    scrollto();

  }, []);

  return (
    <div className={styles.deviceItemPageRoot}>
      <Helmet>
        <title>{renderTitle.title}</title>
        <meta name="description" content={`${renderTitle.title}`} />
      </Helmet>
      <div className={styles.deviceItemPage_wrap}>
        <div className={styles.productpage}>
          <div className={styles.product_direction}>
    
            <button className={styles.product_direct_btn}>
            <NavLink
        to={"/catalog"}
        style={({ isActive, isPending }) => {
          return {
            fontWeight: isActive ? "bold" : "",
            color: isPending ? "darkgrey" : "black",
            textDecoration: "none",
          };
        }}
      >Каталог
        </NavLink></button>
          

            <div className={styles.product_direct_arrow} />

            <button className={styles.product_direct_btn}>
              {renderBrand.brand === "crestron"
                ? "Crestron"
                : renderBrand.brand === "si"
                ? "Screen Innovation"
                : renderBrand.brand === "origin"
                ? "Origin Acoustics"
                : renderBrand.brand === "sonos"
                ? "Sonos"
                : renderBrand.brand === "unitech"
                ? "Unitech Systems"
                : renderBrand.brand === "artnovion"
                ? "Artnovion"
                : "Atelier Luxus"}
            </button>

            <div className={styles.product_direct_arrow} />

            <button className={styles.product_direct_btn}>
              {renderTitle.title}
            </button>
          </div>
          <DeviceItemPagePart product={products} id={renderId} title={renderTitle.title} describe={renderText.text} status={renderStatus.status} photos = {renderArr}/>
        </div>
      </div>
      <NavLink
        to={"/catalog"}
        style={({ isActive, isPending }) => {
          return {
            fontWeight: isActive ? "bold" : "",
            color: isPending ? "darkgrey" : "black",
            textDecoration: "none",
          };
        }}
      >
        <button className={styles.deviceItemPage_btn}>Інші девайси</button>
      </NavLink>

      <div className={styles.deviceItem_footer}>
        <Footer />
      </div>
    </div>
  );
}

export default DeviceItemPage;
