import { createSlice } from "@reduxjs/toolkit"; // with immer and produce

const filterSliceNews = createSlice({
  name: "filterNews",
  initialState: {
    isOpenFilter:     false,
    checkedCrestron:  false,
    checkedSI:        false,
    checkedOrigin:    false,
    checkedSonos:     false,
    checkedUnitech:   false,
    checkedArtnovion: false,
    checkedAtelier:   false,
  
  },
  reducers: {

    setOpenFilter: (state, action) => {
      state.isOpenFilter = action.payload;
    },

    setCheckCrestron: (state, action) => {
      state.checkedCrestron = action.payload;
    },

    setCheckSI: (state, action) => {
      state.checkedSI = action.payload;
    },

    setCheckOrigin: (state, action) => {
      state.checkedOrigin = action.payload;
    },

    setCheckSonos: (state, action) => {
      state.checkedSonos = action.payload;
    },

    setCheckUnitech: (state, action) => {
      state.checkedUnitech = action.payload;
    },

    setCheckArtnovion: (state, action) => {
      state.checkedArtnovion = action.payload;
    },

    setCheckAtelier: (state, action) => {
      state.checkedAtelier = action.payload;
    },

    setSearchVal: (state, action) => {
      state.searchVal = action.payload;
    },

    setReset: (state, action) => {
      state.checkedCrestron = action.payload;
      state.checkedSI = action.payload;
      state.checkedOrigin = action.payload;
      state.checkedSonos = action.payload;
      state.checkedUnitech= action.payload;
      state.checkedArtnovion = action.payload; 
      state.checkedAtelier = action.payload; 
    },
  },
});

export const { setOpenFilter, setCheckCrestron, setCheckSI, setCheckOrigin, setCheckSonos, setCheckUnitech, setCheckArtnovion, setCheckAtelier, setSearchVal, setReset } = filterSliceNews.actions;

export default filterSliceNews.reducer;
