import React from "react";
import styles from "./OurBrands.module.scss";
import brand_1 from "../../img/Brand_Crestron.png";
import brand_2 from "../../img/Brand_SI.png";
import brand_3 from "../../img/Brand_origin.png";
import brand_4 from "../../img/Brand_Sonos.png";
import brand_5 from "../../img/Brand_Unitech.png";
import brand_6 from "../../img/Brand_Artnovion.png";
import brand_7 from "../../img/Brand_Atelier.png";
import brand_8 from "../../img/Brand_add.png";
import { NavLink } from "react-router-dom";
import { setReset, setCategory } from "../../store/slices/itemsOurBrands";
import { useDispatch } from "react-redux";
import { setSearchVal } from "../../store/slices/filterSlice";

const OurBrands = () => {
  const dispatch = useDispatch();
  return (
    <div className={styles.root}>
      <div className={styles.title}>Наші бренди</div>

      <div className={styles.brand_grid}>
        <div className={styles.brand_foto_1}>
          <NavLink
            to={"/crestron"}
            onClick={() => {
             dispatch(setReset(""));
              dispatch(setCategory("crestron"));
              dispatch(setSearchVal(""));
            }}
            style={({ isActive, isPending }) => {
              return {
                fontWeight: isActive ? "bold" : "",
                color: isPending ? "darkgrey" : "black",
                textDecoration: "none",
              };
            }}
          >
            <img src={brand_1} alt="crestron"></img>
          </NavLink>
        </div>
        <div className={styles.brand_foto_2}>
        <NavLink
            to={"/screen-innovations"}
            onClick={() => {
              dispatch(setReset(""));
              dispatch(setCategory("si"));
              dispatch(setSearchVal(""));
            }}
            style={({ isActive, isPending }) => {
              return {
                fontWeight: isActive ? "bold" : "",
                color: isPending ? "darkgrey" : "black",
                textDecoration: "none",
              };
            }}
          >
          <img src={brand_2} alt="si screen innovations"></img>
          </NavLink>
        </div>
        <div className={styles.brand_foto_3}>
        <NavLink
            to={"/origin-acoustics"}
            onClick={() => {
              dispatch(setReset(""));
              dispatch(setCategory("origin"));
              dispatch(setSearchVal(""));
            }}
            style={({ isActive, isPending }) => {
              return {
                fontWeight: isActive ? "bold" : "",
                color: isPending ? "darkgrey" : "black",
                textDecoration: "none",
              };
            }}
          >
          <img src={brand_3} alt="origin acoustics"></img>
          </NavLink>
        </div>
        <div className={styles.brand_foto_4}>
        <NavLink
            to={"/sonos"}
            onClick={() => {
              dispatch(setReset(""));
              dispatch(setCategory("sonos"));
              dispatch(setSearchVal(""));
            }}
            style={({ isActive, isPending }) => {
              return {
                fontWeight: isActive ? "bold" : "",
                color: isPending ? "darkgrey" : "black",
                textDecoration: "none",
              };
            }}
          >
          <img src={brand_4} alt="sonos"></img>
          </NavLink>
        </div>
        <div className={styles.brand_foto_5}>
        <NavLink
            to={"/unitech"}
            onClick={() => {
              dispatch(setReset(""));
              dispatch(setCategory("unitech"));
              dispatch(setSearchVal(""));
            }}
            style={({ isActive, isPending }) => {
              return {
                fontWeight: isActive ? "bold" : "",
                color: isPending ? "darkgrey" : "black",
                textDecoration: "none",
              };
            }}
          >
          <img src={brand_5} alt="unitech systems"></img>
          </NavLink>
        </div>
        <div className={styles.brand_foto_6}>
        <NavLink
            to={"/artnovion"}
            onClick={() => {
              dispatch(setReset(""));
              dispatch(setCategory("artnovion"));
              dispatch(setSearchVal(""));
            }}
            style={({ isActive, isPending }) => {
              return {
                fontWeight: isActive ? "bold" : "",
                color: isPending ? "darkgrey" : "black",
                textDecoration: "none",
              };
            }}
          >
          <img src={brand_6} alt="artnovion"></img>
          </NavLink>
        </div>
        <div className={styles.brand_foto_7}>
        <NavLink
            to={"/atelier-luxus"}
            onClick={() => {
              dispatch(setReset(""));
              dispatch(setCategory("atelier"));
              dispatch(setSearchVal(""));
            }}
            style={({ isActive, isPending }) => {
              return {
                fontWeight: isActive ? "bold" : "",
                color: isPending ? "darkgrey" : "black",
                textDecoration: "none",
              };
            }}
          >
          <img src={brand_7} alt="atelier luxus"></img>
          </NavLink>
        </div>
        <div className={styles.brand_foto_8}>
        <NavLink
            to={"/brands"}
            style={({ isActive, isPending }) => {
              return {
                fontWeight: isActive ? "bold" : "",
                color: isPending ? "darkgrey" : "black",
                textDecoration: "none",
              };
            }}
          >
          <img src={brand_8} alt="some group"></img>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default OurBrands;
