import React, { useEffect, useMemo } from "react";
import styles from "./NewsPage.module.scss";
import { Helmet } from "react-helmet";
import Footer from "../../components/Footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import { setOpenFilter } from "../../store/slices/filterSliceNews";
import { setIsOpen } from "../../store/slices/modalFiltrNewsSlice";
import filter_btn from "../../img/News/fltr_btn.png";
import FilterSetPhone from "../../components/FilterNewsPhone/FilterNewsPhone";
import FilterSetTablet from "../../components/FilterNewsTablet/FilterNewsTablet";
import { setIsFlag } from "../../store/slices/flagNewsSlice";
import { fetchNews } from "../../store/slices/newsSlice";
import Preloader from "../../components/Preloader/index";
import CardItemNewsAll from "../../components/CardItemNewsAll/CardItemNewsAll";
import BannerCatalog from "../../components/BannerCatalog/BannerCatalog";

function NewsPage() {
  const scrollto = () => {
    window.scrollTo(0, 0);
  };

  const dispatch = useDispatch();
  const news = useSelector((store) => store.news.data);

  const checkedCrestron = useSelector(
    (store) => store.filterNews.checkedCrestron
  );
  const checkedSI = useSelector((store) => store.filterNews.checkedSI);
  const checkedOrigin = useSelector((store) => store.filterNews.checkedOrigin);
  const checkedSonos = useSelector((store) => store.filterNews.checkedSonos);
  const checkedUnitech = useSelector(
    (store) => store.filterNews.checkedUnitech
  );
  const checkedArtnovion = useSelector(
    (store) => store.filterNews.checkedArtnovion
  );
  const checkedAtelier = useSelector(
    (store) => store.filterNews.checkedAtelier
  );

  const isOpen = useSelector((store) => store.modalNews.isOpen);
  const isLoading = useSelector((store) => store.news.isLoading);

  const openModal = () => {
    dispatch(setIsOpen(true));
  };

  const closeModal = () => {
    const timeout_1 = setTimeout(() => {
      dispatch(setIsFlag(true));
    }, 0);
    const timeout_2 = setTimeout(() => {
      dispatch(setIsFlag(false));
    }, 1000);
    const timeout_3 = setTimeout(() => {
      dispatch(setIsOpen(false));
    }, 700);
    return () => {
      clearTimeout(timeout_1);
      clearTimeout(timeout_2);
      clearTimeout(timeout_3);
    };
  };
  const handleClModal = () => {
    if (isOpen) {
      return closeModal();
    }
    return openModal();
  };

  const fetchData = useMemo(
    () => () => {
      dispatch(fetchNews());
    },
    [dispatch]
  );

  useEffect(() => {
    scrollto();
    fetchData();
  }, [fetchData]);

  const filteredNews = news.filter(
    (newsItem) =>
      (!checkedCrestron &&
        !checkedSI &&
        !checkedOrigin &&
        !checkedSonos &&
        !checkedUnitech &&
        !checkedArtnovion &&
        !checkedAtelier) ||
      (checkedCrestron && newsItem.brand === "crestron") ||
      (checkedSI && newsItem.brand === "si") ||
      (checkedOrigin && newsItem.brand === "origin") ||
      (checkedSonos && newsItem.brand === "sonos") ||
      (checkedUnitech && newsItem.brand === "unitech") ||
      (checkedArtnovion && newsItem.brand === "artnovion") ||
      (checkedAtelier && newsItem.brand === "atelier")
  );

  return (
    <div className={styles.newsPageRoot}>
      <Helmet>
        <title>Новини</title>
        <meta
          name="description"
          content="Новини та новинки відомих брендв. Напишіть нам, щоб отримати детальнішу інформацію. Бренди, представниками яких є компанія Д8: Crestron, Sonos, Screen Innovations, Origin Acoustic, Unitech, ArtNovion, Atelier Luxus"
        />
        <link rel="canonical" href="https://brands.d8.com.ua/all-news" />
      </Helmet>
      <div className={styles.newsPage_wrap}>
        <div className={styles.newspage}>
          <BannerCatalog/>
          <h1 className={styles.newspage_head}>Новини</h1>
          <button
            className={styles.newspage_fltr_btn}
            onClick={() => {
              handleClModal();
              dispatch(setOpenFilter(true));
            }}
          >
            <img
              className={styles.newspage_fltr_icon}
              src={filter_btn}
              alt="Filter Icon"
            />
            Фільтр
          </button>

          <FilterSetPhone />
          <FilterSetTablet />
          {isLoading ? (
            <div className={styles.newsPage_preloader}>
              <Preloader />
            </div>
          ) : (
            <div className={styles.newspage_div}>
              {filteredNews.map((item) => (
                <CardItemNewsAll
                  key={item._id}
                  id={item._id}
                  text={item.text}
                  title={item.title}
                  img={item.img}
                  dataPost={item.data_post}
                  img2={item.img2}
                  img3={item.img3}
                  brand={item.brand}
                />
              ))}
            </div>
          )}
        </div>
      </div>

      <div className={styles.news_footer}>
        <Footer />
      </div>
    </div>
  );
}

export default NewsPage;
