import React, { useEffect, useCallback, useMemo } from "react";
import styles from "./CatalogFilterMainPage.module.scss";
import { Helmet } from "react-helmet";
import Footer from "../../components/Footer/Footer";
import search_icon from "../../img/Catalog/search.png";
import auto_logo from "../../img/Catalog/automatization-logo.png";
import acoustic_logo from "../../img/Catalog/acoustic-logo.png";
import cinema_logo from "../../img/Catalog/cinema-logo.png";
import furniture_logo from "../../img/Catalog/furniture-logo.png";
import crestron_logo from "../../img/Catalog/crestron-logo.png";
import si_logo from "../../img/Catalog/screen-innovations-logo.png";
import origin_logo from "../../img/Catalog/origin-acoustics-logo.png";
import sonos_logo from "../../img/Catalog/sonos-logo.png";
import unitech_logo from "../../img/Catalog/unitech-systems-logo.png";
import artnovion_logo from "../../img/Catalog/artnovion-logo.png";
import atelier_logo from "../../img/Catalog/atelier-luxus-logo.png";
import DeviceItem from "../../components/DeviceItem/DeviceItem";
import { useDispatch, useSelector } from "react-redux";
import { fetchProduct } from "../../store/slices/productsSlice";
import { setSearchVal, setCategory } from "../../store/slices/filterSlice";
// import Preloader from "../../components/Preloader/index";
import { NavLink } from "react-router-dom";
import { setReset } from "../../store/slices/filterSlice";
import PreloaderSkeleton from "../../components/PreloaderSkeleton/PreloaderSkeleton";

function CatalogFilterMainPage() {
  const scrollto = () => {
    window.scrollTo(0, 0);
  };

  const dispatch = useDispatch();
  const products = useSelector((store) => store.products.data);
  const isLoading = useSelector((store) => store.products.isLoading);
  const searchVal = useSelector((store) => store.filters.searchVal);
  // console.log(products);

  const fetchData = useCallback(() => {
    dispatch(setSearchVal(""));
  }, [dispatch]);

  const fetchDataProduct = useMemo(
    () => () => {
      dispatch(fetchProduct());
    },
    [dispatch]
  );

  useEffect(() => {
    fetchData();
    fetchDataProduct();
    scrollto();
  }, [fetchData, fetchDataProduct]);

  const filterBySearch = products.filter((item) => {
    return item.title.toLowerCase().includes(searchVal.toLowerCase());
  });

  return (
    <div className={styles.catalogFilterPageRoot}>
      <Helmet>
        <title>Каталог продуктів</title>
        <meta
          name="description"
          content="Каталог продуктів. Напишіть нам, щоб отримати детальнішу інформацію. Бренди, представниками яких є компанія Д8: Crestron, Sonos, Screen Innovations, Origin Acoustic, Unitech, ArtNovion, Atelier Luxus"
        />
        <link rel="canonical" href="https://brands.d8.com.ua/catalog" />
      </Helmet>
      <div className={styles.catalogFilterPage}>
        {isLoading ? (
          <div className={styles.scatalogPage_preloader}>
            <PreloaderSkeleton/>
          </div>
        ) : (
          <div className={styles.scatalogpage}>
            <h1 className={styles.scatalogpage_head}>Каталог</h1>

            <input
              type="text"
              name="finder_outside"
              placeholder="назва продукту"
              onChange={(e) => dispatch(setSearchVal(e.target.value))}
              className={styles.scatalogpage_finder}
            />
            <img
              className={styles.scatalogpage_search}
              src={search_icon}
              alt="Search Icon"
            />

            {searchVal !== "" ? (
              <div className={styles.scatalog_div}>
                {filterBySearch.map((item) => (
                  <DeviceItem
                    title={item.title}
                    img={item.img}
                    id={item._id}
                    key={item._id}
                    status={item.status}
                    text={item.describe}
                    img1={item.add_img1}
                    img2={item.add_img2}
                    img3={item.add_img3}
                    img4={item.add_img4}
                    img5={item.add_img5}
                    brand={item.brand}
                  />
                ))}
              </div>
            ) : (
              <div>
                <div className={styles.scatalogpage_line} />
                <p className={styles.scatalogpage_subtitle}>За категорією</p>

                <div className={styles.scatalogpage_btn_div}>
                  <NavLink
                    to={"/catalog-chosen-category"}
                    style={({ isActive, isPending }) => {
                      return {
                        fontWeight: isActive ? "bold" : "",
                        color: isPending ? "" : "",
                        textDecoration: "none",
                      };
                    }}
                  >
                    <button
                      className={styles.scatalogpage_btn}
                      onClick={() => {
                        dispatch(setCategory("automatization"));
                        dispatch(setReset(false));
                      }}
                    >
                      <img
                        className={styles.scatalogpage_img}
                        src={auto_logo}
                        alt="Automatization Logo"
                      />
                      Автоматизація
                      <div className={styles.scatalogpage_arrow_img} />
                    </button>
                  </NavLink>
                  <NavLink
                    to={"/catalog-chosen-category"}
                    style={({ isActive, isPending }) => {
                      return {
                        fontWeight: isActive ? "bold" : "",
                        color: isPending ? "" : "",
                        textDecoration: "none",
                      };
                    }}
                  >
                    <button
                      className={styles.scatalogpage_btn}
                      onClick={() => {
                        dispatch(setCategory("acoustic"));
                        dispatch(setReset(false));
                      }}
                    >
                      <img
                        className={styles.scatalogpage_img}
                        src={acoustic_logo}
                        alt="Acoustic Logo"
                      />
                      Акустика
                      <div className={styles.scatalogpage_arrow_img} />
                    </button>
                  </NavLink>

                  <NavLink
                    to={"/catalog-chosen-category"}
                    style={({ isActive, isPending }) => {
                      return {
                        fontWeight: isActive ? "bold" : "",
                        color: isPending ? "" : "",
                        textDecoration: "none",
                      };
                    }}
                  >
                    <button
                      className={styles.scatalogpage_btn}
                      onClick={() => {
                        dispatch(setCategory("cinema"));
                        dispatch(setReset(false));
                      }}
                    >
                      <img
                        className={styles.scatalogpage_img}
                        src={cinema_logo}
                        alt="Cinema Logo"
                      />
                      Кінотеатр
                      <div className={styles.scatalogpage_arrow_img} />
                    </button>
                  </NavLink>
                  <NavLink
                    to={"/catalog-chosen-category"}
                    style={({ isActive, isPending }) => {
                      return {
                        fontWeight: isActive ? "bold" : "",
                        color: isPending ? "" : "",
                        textDecoration: "none",
                      };
                    }}
                  >
                    <button
                      className={styles.scatalogpage_btn}
                      onClick={() => {
                        dispatch(setCategory("furniture"));
                        dispatch(setReset(false));
                      }}
                    >
                      <img
                        className={styles.scatalogpage_img}
                        src={furniture_logo}
                        alt="Furniture Logo"
                      />
                      Фурнітура
                      <div className={styles.scatalogpage_arrow_img} />
                    </button>
                  </NavLink>
                </div>

                <div className={styles.scatalogpage_line_1} />
                <p className={styles.scatalogpage_subtitle}>За брендом</p>

                <div className={styles.scatalogpage_btn_div_1}>
                  <NavLink
                    to={"/catalog-chosen-category"}
                    style={({ isActive, isPending }) => {
                      return {
                        fontWeight: isActive ? "bold" : "",
                        color: isPending ? "" : "",
                        textDecoration: "none",
                      };
                    }}
                  >
                    <button
                      className={styles.scatalogpage_btn_1}
                      onClick={() => {
                        dispatch(setCategory("crestron"));
                        dispatch(setReset(false));
                      }}
                    >
                      <img
                        className={styles.scatalogpage_img_1}
                        src={crestron_logo}
                        alt="Crestron Logo"
                      />
                      Crestron
                      <div className={styles.scatalogpage_arrow_img} />
                    </button>
                  </NavLink>
                  <NavLink
                    to={"/catalog-chosen-category"}
                    style={({ isActive, isPending }) => {
                      return {
                        fontWeight: isActive ? "bold" : "",
                        color: isPending ? "" : "",
                        textDecoration: "none",
                      };
                    }}
                  >
                    <button
                      className={styles.scatalogpage_btn_1}
                      onClick={() => {
                        dispatch(setCategory("si"));
                        dispatch(setReset(false));
                      }}
                    >
                      <img
                        className={styles.scatalogpage_img_1}
                        src={si_logo}
                        alt="Screen Innovation Logo"
                      />
                      SI
                      <div className={styles.scatalogpage_arrow_img} />
                    </button>
                  </NavLink>
                  <NavLink
                    to={"/catalog-chosen-category"}
                    style={({ isActive, isPending }) => {
                      return {
                        fontWeight: isActive ? "bold" : "",
                        color: isPending ? "" : "",
                        textDecoration: "none",
                      };
                    }}
                  >
                    <button
                      className={styles.scatalogpage_btn_1}
                      onClick={() => {
                        dispatch(setCategory("origin"));
                        dispatch(setReset(false));
                      }}
                    >
                      <img
                        className={styles.scatalogpage_img_1}
                        src={origin_logo}
                        alt="Origin Acoustics Logo"
                      />
                      Origin Acoustics
                      <div className={styles.scatalogpage_arrow_img} />
                    </button>
                  </NavLink>
                  <NavLink
                    to={"/catalog-chosen-category"}
                    style={({ isActive, isPending }) => {
                      return {
                        fontWeight: isActive ? "bold" : "",
                        color: isPending ? "" : "",
                        textDecoration: "none",
                      };
                    }}
                  >
                    <button
                      className={styles.scatalogpage_btn_1}
                      onClick={() => {
                        dispatch(setCategory("sonos"));
                        dispatch(setReset(false));
                      }}
                    >
                      <img
                        className={styles.scatalogpage_img_1}
                        src={sonos_logo}
                        alt="Sonos Logo"
                      />
                      Sonos
                      <div className={styles.scatalogpage_arrow_img} />
                    </button>
                  </NavLink>
                  <NavLink
                    to={"/catalog-chosen-category"}
                    style={({ isActive, isPending }) => {
                      return {
                        fontWeight: isActive ? "bold" : "",
                        color: isPending ? "" : "",
                        textDecoration: "none",
                      };
                    }}
                  >
                    <button
                      className={styles.scatalogpage_btn_1}
                      onClick={() => {
                        dispatch(setCategory("unitech"));
                        dispatch(setReset(false));
                      }}
                    >
                      <img
                        className={styles.scatalogpage_img_1}
                        src={unitech_logo}
                        alt="Origin Acoustics Logo"
                      />
                      Unitech Systems
                      <div className={styles.scatalogpage_arrow_img} />
                    </button>
                  </NavLink>
                  <NavLink
                    to={"/catalog-chosen-category"}
                    style={({ isActive, isPending }) => {
                      return {
                        fontWeight: isActive ? "bold" : "",
                        color: isPending ? "" : "",
                        textDecoration: "none",
                      };
                    }}
                  >
                    <button
                      className={styles.scatalogpage_btn_1}
                      onClick={() => {
                        dispatch(setCategory("artnovion"));
                        dispatch(setReset(false));
                      }}
                    >
                      <img
                        className={styles.scatalogpage_img_1}
                        src={artnovion_logo}
                        alt="Origin Acoustics Logo"
                      />
                      Artnovion
                      <div className={styles.scatalogpage_arrow_img} />
                    </button>
                  </NavLink>
                  <NavLink
                    to={"/catalog-chosen-category"}
                    style={({ isActive, isPending }) => {
                      return {
                        fontWeight: isActive ? "bold" : "",
                        color: isPending ? "" : "",
                        textDecoration: "none",
                      };
                    }}
                  >
                    <button
                      className={styles.scatalogpage_btn_1}
                      onClick={() => {
                        dispatch(setCategory("atelier"));
                        dispatch(setReset(false));
                      }}
                    >
                      <img
                        className={styles.scatalogpage_img_1}
                        src={atelier_logo}
                        alt="Atelier Luxus Logo"
                      />
                      Atelier Luxus
                      <div className={styles.scatalogpage_arrow_img} />
                    </button>
                  </NavLink>
                </div>
              </div>
            )}
          </div>
        )}
        {/* <PreloaderSkeleton/> */}
      </div>
      <div className={styles.scatalog_footer}>
        <Footer />
      </div>
    </div>
  );

  // }
}

export default CatalogFilterMainPage;
