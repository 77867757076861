import { createSlice } from "@reduxjs/toolkit"; // with immer and produce

const flagHeadSlice = createSlice({
    name: 'flagHead',
    initialState: {
        isFlagHead: false
    },
    reducers: {
        setIsFlagHead: (state, action) => {
            state.isFlagHead = action.payload;
        },

    }
});

export const { setIsFlagHead } = flagHeadSlice.actions;

export default flagHeadSlice.reducer;