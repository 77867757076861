import { createSlice } from "@reduxjs/toolkit"; // with immer and produce

const filterSlice = createSlice({
  name: "filter",
  initialState: {
    chosenCategoryOrBrand: "acoustic",
    isOpenFilter: false,
    crestronProduct: false,
    siProduct: false,
    originProduct: false,
    sonosProduct: false,
    unitechProduct: false,
    artnovionProduct: false,
    atelierProduct: false,
    searchVal: "",
  },
  reducers: {
    setCategory: (state, action) => {
      state.chosenCategoryOrBrand = action.payload;
    },

    setOpenFilter: (state, action) => {
      state.isOpenFilter = action.payload;
    },

    setCrestronProduct: (state, action) => {
      state.crestronProduct = action.payload;
    },

    setSiProduct: (state, action) => {
      state.siProduct = action.payload;
    },

    setOriginProduct: (state, action) => {
      state.originProduct = action.payload;
    },

    setSonosProduct: (state, action) => {
      state.sonosProduct = action.payload;
    },

    setUnitechProduct: (state, action) => {
      state.unitechProduct = action.payload;
    },

    setArtnovionProduct: (state, action) => {
      state.artnovionProduct = action.payload;
    },

    setAtelierProduct: (state, action) => {
      state.atelierProduct = action.payload;
    },

    setSearchVal: (state, action) => {
      state.searchVal = action.payload;
    },

    setReset: (state, action) => {
      state.crestronProduct = action.payload;
      state.siProduct = action.payload;
      state.originProduct = action.payload;
      state.sonosProduct = action.payload;
      state.unitechProduct = action.payload;
      state.artnovionProduct = action.payload; 
      state.atelierProduct = action.payload; 
    },
  },
});

export const { setCategory, setOpenFilter, setCrestronProduct, setSiProduct, setOriginProduct, setSonosProduct, setUnitechProduct, setArtnovionProduct, setAtelierProduct, setSearchVal, setReset } = filterSlice.actions;

export default filterSlice.reducer;
