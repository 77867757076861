import { createSlice } from "@reduxjs/toolkit"; // with immer and produce

const modalFiltrDeviceSlice = createSlice({
    name: 'modalDevice',
    initialState: {
        isOpen: false
    },
    reducers: {
        setIsOpen: (state, action) => {
            state.isOpen = action.payload;
        },

    }
});

export const { setIsOpen } = modalFiltrDeviceSlice.actions;

export default modalFiltrDeviceSlice.reducer;