import { PureComponent } from "react";
import styles from "./Sonos.module.scss";
import { Helmet } from "react-helmet";
import Footer from "../../components/Footer/Footer";
import sonos_1 from "../../img/Sonos/sonos-1.png";
import sonos_2 from "../../img/Sonos/sonos-2.png";
import sonos_3 from "../../img/Sonos/sonos-3.png";
import sonos_4 from "../../img/Sonos/sonos-4.png";
import sonos_5 from "../../img/Sonos/sonos-5.png";
import sonos_6 from "../../img/Sonos/sonos-6.png";
import OurBrandsCatalog from "../../components/OurBrandsCatalog/OurBrandsCatalog";

class SonosPage extends PureComponent {
  scrollto = () => {
    window.scrollTo(0, 0);
  };

  componentDidMount() {
    this.scrollto();
  }
  render() {
    return (
      <div className={styles.sonosPageRoot}>
        <Helmet>
          <title>Sonos</title>
          <meta
            name="description"
            content="Sonos  - бездротові колонки об'єднують кожну кімнату з неймовірним звучанням для музики, телебачення і не тільки. Персональні кінотеатри. Це найкраща в своєму класі акустика з найсучаснішим програмним забезпеченням. Це художній підхід до звуку. TruePlay. Голосове управління"
          />
          <link rel="canonical" href="https://brands.d8.com.ua/sonos" />
        </Helmet>
        <div className={styles.sonosPage}>
          <div className={styles.sonos}>
            <h1 className={styles.sonos_article}>Про Sonos</h1>
            <img
              src={sonos_1}
              className={styles.sonos_photo1}
              alt="sonos people inside"
            />
            <h2 className={styles.sonos_subarticle1}>
              Розумна звукова система
            </h2>
            <p className={styles.sonos_describe1}>
              Бездротові колонки Sonos об'єднують кожну кімнату і кожного з нас
              неймовірним звучанням для музики, телебаченя і не тільки. Sonos
              дозволяє легко відтворювати те, що ви любите - музику, подкасти,
              фільми, шоу, аудіокниги, радіо та багато іншого - і ділитися цим
              вголос з тими, кого ви любите.
            </p>
            <div className={styles.sonos_flex_1}>
              <img
                src={sonos_2}
                className={styles.sonos_photo2}
                alt="sonos people"
              />
              <div className={styles.sonos_inside_1}>
                <h2 className={styles.sonos_subarticle2}>
                  Візіонери бездротового аудіо
                </h2>
                <p className={styles.sonos_describe2}>
                  Ми не обмеженні тим, що можливо зараз. маючи сміливе бачення
                  майбутнього домашнього звуку, ми ретельно розробили
                  технологію, необхідну для втілення його в життя. <br />
                  <br />
                  Четверо засновників компанії - Джон Макфарлейн, Том Каллен,
                  Трунг Май і Крейг Шелбурн - втілили в життя сміливе бачення,
                  засноване на технологіях, яких на час ще не існувало.
                  Натхненні успіхом першого етапу побудови бізнесу в Інтернеті,
                  вони обрали своєю наступною місією новий спосіб принести
                  музику в кожну оселю - бездротово, в декілька кімнат, з
                  комп'ютера та Інтернету, з чудовим звуком. Вони найняли чудову
                  команду, яка створила дивовижні продукти з нуля, а музичні
                  фанати по всьому світу знайшли новий бренд, в який закохалися.
                </p>
              </div>
            </div>

            <div className={styles.sonos_flex_2}>
              <img
                src={sonos_3}
                className={styles.sonos_photo3}
                alt="sonos catalog"
              />
              <div className={styles.sonos_inside_2}>
                <h2 className={styles.sonos_subarticle3}>Дивовижно чітко</h2>
                <p className={styles.sonos_describe3}>
                  Кожна колонка продумана зсередини з найкращою у своєму класі
                  акустикою та найсучаснішим програмним забезпеченням.
                </p>
                <div className={styles.sonos_mini_div}>
                  <p className={styles.sonos_text_2100}>2100+</p>
                  <p className={styles.sonos_text_mini}>
                    запатентованих інновацій
                  </p>
                </div>
              </div>
            </div>

            <div className={styles.sonos_flex_3}>
              <img
                src={sonos_4}
                className={styles.sonos_photo4}
                alt="sonos art expert"
              />
              <div className={styles.sonos_inside_3}>
                <h2 className={styles.sonos_subarticle4}>
                  Експертно налаштований
                </h2>
                <p className={styles.sonos_describe4}>
                  Продюсери, мікшери та артисти, які отримали премії Оскар і
                  Греммі, допрацьовують звучання до найдрібніших деталей. Тому
                  ви не просто чуєте кожну деталь. Ви відчуваєте емоції.
                </p>
                <h3 className={styles.sonos_subarticle4_1}>
                  «Це художній підхід до звуку».
                </h3>
                <p className={styles.sonos_describe4_1}>
                  -Джайлз Мартін <br />
                  <br />
                  Музичний продюсер, володар премії Греммі та лідер звукового
                  досвіду Sonos.
                </p>
              </div>
            </div>

            <div className={styles.sonos_flex_4}>
              <img
                src={sonos_5}
                className={styles.sonos_photo5}
                alt="sonos arc tv"
              />
              <div className={styles.sonos_inside_4}>
                <h2 className={styles.sonos_subarticle5}>
                  Прекрасно збалансований
                </h2>
                <p className={styles.sonos_describe5}>
                  Запатентована технологія налаштування Trueplay™ адаптує звук
                  до вашого унікального оточення, створюючи неперевершені
                  враження від прослуховування.
                </p>
              </div>
            </div>

            <div className={styles.sonos_flex_5}>
              <img
                src={sonos_6}
                className={styles.sonos_photo6}
                alt="sonos together app"
              />
              <div className={styles.sonos_inside_5}>
                <h2 className={styles.sonos_subarticle6}>
                  Керуйте всім, як за помахом чарівної палички
                </h2>
                <p className={styles.sonos_describe6}>
                  Додаток Sonos об'єднує весь ваш контент і налаштування в єдине
                  ціле без зайвих зусиль. Ви також можете використовувати свій
                  голос, Apple AirPlay 2 та інші.
                </p>
              </div>
            </div>
            <OurBrandsCatalog />
          </div>

          <Footer />
        </div>
      </div>
    );
  }
}

export default SonosPage;
