import { createSlice } from "@reduxjs/toolkit"; // with immer and produce

const modalFiltrNewsSlice = createSlice({
    name: 'modalNews',
    initialState: {
        isOpen: false
    },
    reducers: {
        setIsOpen: (state, action) => {
            state.isOpen = action.payload;
        },

    }
});

export const { setIsOpen } = modalFiltrNewsSlice.actions;

export default modalFiltrNewsSlice.reducer;