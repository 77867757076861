import { PureComponent } from "react";
import styles from "./Footer.module.scss";
import { NavLink } from "react-router-dom";
import YouTube from "../../img/footerBlck_youtube_button.png";
import Instagram from "../../img/footerBlck_instagram_button.png";
import Facebook from "../../img/footerBlck_facebook_button.png";
import Linkedin from "../../img/footerBlck_linkedin_button.png";
import TikTok from "../../img/footerBlck_tiktok_button.png";


class Footer extends PureComponent {
  scrollto = () => {
    window.scrollTo(0, 0);
  };
  render() {
    return (
      <footer className={styles.root} id="footer">
        <div className={styles.footerRights}>
        D8 Brands © 2024 Всі права захищені
        </div>
        <div className={styles.footerMail}>
        <a href="mailto:brands@d8.com.ua">
          brands@d8.com.ua
        </a>
        </div>
        
        <div className={styles.footerMenu_socIcon}>
          <NavLink
            to="https://www.instagram.com/d8_engineering/"
            target="_blank"
          >
            <img
              src={Instagram}
              alt="Instagram Icon"
              className={styles.footerMenu_instagram}
            ></img>
          </NavLink>

          <NavLink to="https://www.facebook.com/d8engineer" target="_blank">
            <img
              src={Facebook}
              alt="Facebook Icon"
              className={styles.footerMenu_facebook}
            ></img>
          </NavLink>
          <NavLink to="https://www.youtube.com/@D8Engineering" target="_blank">
            <img
              src={YouTube}
              alt="Youtube Icon"
              className={styles.footerMenu_youtube}
            ></img>
          </NavLink>
          <NavLink to="https://www.linkedin.com/company/d8-engineering" target="_blank">
            <img
              src={Linkedin}
              alt="Linkedin Icon"
              className={styles.footerMenu_linkedin}
            ></img>
          </NavLink>
          <NavLink to="https://www.tiktok.com/@d8.engineering" target="_blank">
            <img
              src={TikTok}
              alt="TikTok Icon"
              className={styles.footerMenu_tiktok}
            ></img>
          </NavLink>
        
          </div>
      </footer>
    );
  }
}

export default Footer;
