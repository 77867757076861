import { createSlice } from "@reduxjs/toolkit"; // with immer and produce

const flagNewsSlice = createSlice({
    name: 'flagNews',
    initialState: {
        isFlag: false
    },
    reducers: {
        setIsFlag: (state, action) => {
            state.isFlag = action.payload;
        },

    }
});

export const { setIsFlag } = flagNewsSlice.actions;

export default flagNewsSlice.reducer;