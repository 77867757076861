import { PureComponent } from "react";
import styles from "./ScreenInnovationPage.module.scss";
import { Helmet } from "react-helmet";
import Footer from "../../components/Footer/Footer";
import screen_1 from "../../img/Screen Innovation/screen-1.png";
import screen_2 from "../../img/Screen Innovation/screen-2.png";
import screen_3 from "../../img/Screen Innovation/screen-3.png";
import screen_4 from "../../img/Screen Innovation/screen-4.png";
import OurBrandsCatalog from "../../components/OurBrandsCatalog/OurBrandsCatalog";

class ScreenInnovationPage extends PureComponent {
  scrollto = () => {
    window.scrollTo(0, 0);
  };

  componentDidMount() {
    this.scrollto();
  }

  render() {
    return (
      <div className={styles.screenInPageRoot}>
        <Helmet>
          <title>Screen Innovations</title>
          <meta
            name="description"
            content="Screen Innovations пропонує інноваційний дизайн моторизованих рулонних штор та проекційних екранів, одночасно спрощцючи автоматизацію та інтеграцію"
          />
          <link
            rel="canonical"
            href="https://brands.d8.com.ua/screen-innovations"
          />
        </Helmet>
        <div className={styles.screenInPage}>
          <div className={styles.screen}>
            <h1 className={styles.screen_article}>Про Screen Innovations</h1>
            <img
              src={screen_1}
              className={styles.screen_photo1}
              alt="screen people look"
            />
            <p className={styles.screen_describe1}>
              Screen Innovations пропонує свіжий інноваційний дизайн
              моторизованих рулонних штор і проєкційних екранів, одночасно
              спрощуючи автоматизацію та інтеграцію. Насолоджуйтесь розумним
              життям, обираючи ексклюзивні енергоефективні тканинні полотна в
              поєднанні з найміцнішою сіткою на ринку для синхронізованого
              підняття та опускання. Перетворіть будь-який простір - в
              приміщенні або на вулиці, вдома або в офісі - на розважальний
              театр світового класу з сучасним, чистим дизайном. <br />
              <br />
              Не наші матеріали, що поглинають навколишнє світло, такі як Black
              Diamond, або низьковольтні варіанти, роблять SI першою зупинкою
              для інновацій в області красивого способу життя; ми прагнемо бути
              надійним партнером для наших дилерів. Компанія Screen Innovations
              прагне до своєчасного обслуговування, простоти встановлення та
              відмінного сервісу для клієнтів, перетворюючи відгуки на
              трансформаційні технології. Якщо ви хочете реалізувати свій
              наступний проект або найбільшу мрію, зв'яжіться з компанією Screen
              Innovations вже сьогодні!
            </p>
            <div className={styles.screen_flex_1}>
              <img
                src={screen_2}
                className={styles.screen_photo2}
                alt="art scene carbon black"
              />
              <div className={styles.screen_inside_1}>
                <h2 className={styles.screen_subarticle1}>CarbonBlack & SI</h2>
                <p className={styles.screen_describe2}>
                  Screen Innovations представляє нову революційну оптику для
                  проєкційних екранів з технологією CarbonBlack. <br />
                  <br />
                  CarbonBlack використовує потужність і роздільну здатність
                  лазерних проекторів, для проєктів, які вимагають виняткової
                  продуктивності та гнучкості дизайну, наприклад для великих,
                  захоплюючих глядацьких аудиторій або великих розважальних
                  закладів, де візуальне занурення аудиторії є обов'язковим,
                  проектор CarbonBlack має стандартний розмір 16" x 99",
                  згортається в рулони для зручності при транспортуванні, може
                  бути вирізаний будь-якої форми або розміру і тепер може
                  монтуватися за допомогою широкого асортименту екранних збірок
                  від SI.
                </p>
              </div>
            </div>

            <div className={styles.screen_flex_2}>
              <img
                src={screen_3}
                className={styles.screen_photo3}
                alt="screen black diamond G3"
              />
              <div className={styles.screen_inside_2}>
                <h2 className={styles.screen_subarticle2}>Black Diamond G3</h2>
                <p className={styles.screen_describe3}>
                  Компанія Screen Innovations (SI), лідер у галузі технологій
                  проекційних екранів, випускає нові екранні матеріали Black
                  Diamond G3. Новий матеріал SI Black Diamond G3 виводить
                  проекцію на новий рівень, усуваючи необхідність у темному
                  спеціальному приміщенні. Тепер глядачі можуть насолоджуватися
                  переглядом в яскравому середовищі будь-де і будь-коли.
                </p>
              </div>
            </div>

            <div className={styles.screen_flex_3}>
              <img
                src={screen_4}
                className={styles.screen_photo4}
                alt="art solo"
              />
              <div className={styles.screen_inside_3}>
                <h2 className={styles.screen_subarticle3}>Solo</h2>
                <p className={styles.screen_describe4}>
                  Solo - це повністю бездротовий моторизований екран з рухомим
                  полотном у стилі Zero Edge®, що живиться від літієвої батареї
                  і працює 2 роки на одному заряді. Компанія SI розробила Solo,
                  щоб його можна було встановлювати для широкого спектру різних
                  застосувань для перегляду розваг у приміщенні або на вулиці,
                  використовуючи широкий спектр нових монтажних аксесуарів, які
                  також випускаються разом із Solo. Одним з таких аксесуарів є
                  кронштейни на присосках, які дозволяють встановлювати проектор
                  на гладкі, непористі поверхні, наприклад, на вікна квартир,
                  тобто спробуйте встановити його на вікна автобуса або
                  позашляховика - використовуйте свою фантазію.
                </p>
              </div>
            </div>
            <OurBrandsCatalog />
          </div>

          <Footer />
        </div>
      </div>
    );
  }
}

export default ScreenInnovationPage;
