import { BrowserRouter } from "react-router-dom";
import { Component } from "react";
import styles from "./App.module.scss";
import Header from "./components/Header/Header.js";
import AppRoutes from "./AppRoutes";
import { Helmet } from "react-helmet";
import ModalMenu from "./components/ModalMenu/ModalMenu.js";

class App extends Component {
  // state = {
  //   posts: [],
  //   isLoading: false,
  // };

  // setPosts = (value) => {
  //   this.setState({ posts: value });
  // };

  // setIsLoading = (value) => {
  //   this.setState({ isLoading: value });
  // };


  // async componentDidMount() {
  //   const getPosts = async () => {
  //     this.setState({isLoading: true});
  //     try {
      
  //         // const posts = await fetch(`${process.env.REACT_APP_API_URL}`).then(
  //         // (res) => res.json()
  //           const posts = await fetch('./news.json').then((res) => res.json());
        
  //       // console.log(posts);
  //       this.setState({posts: posts.data});
    
  //     } catch (error) {
  //       // console.error(error);
  //     } finally {
  //       this.setState({isLoading: false});
  //     }
  //   };

  //   getPosts();
  // }

  render() {
    // const { isLoading, posts } = this.state;

    return (
      <div className={styles.App}>
        <BrowserRouter>
          <>
            <Helmet>
              <meta charSet="utf-8" />
              <title>Brands D8</title>
              <meta
                name="description"
                content="Усі відомі бренди| Crestron | Sonos | XGimi"
              />
            </Helmet>
            <Header />
            <ModalMenu />
            <section>
              <AppRoutes />
            </section>
          </>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
