import React, { useState, useEffect } from "react";
import styles from "./BannerCatalog.module.scss";
import slide1ImageSm from "../../img/loading_banner_crestron_430.jpg";
import slide2ImageSm from "../../img/loading_banner_sonos_430.jpg";
import slide3ImageSm from "../../img/loading_banner_atelier_430.jpg";
import slide4ImageSm from "../../img/loading_banner_artnovion_430.jpg";

import slide1Image431 from "../../img/loading_banner_crestron_430-768.png";
import slide2Image431 from "../../img/loading_banner_sonos_430-768.png";
import slide3Image431 from "../../img/loading_banner_atelier_430-768.png";
import slide4Image431 from "../../img/loading_banner_artnovion_430-768.png";

import slide1Image768 from "../../img/Crestron_load_banner_800.png";
import slide2Image768 from "../../img/sonos_load_banner_800.png";
import slide3Image768 from "../../img/atelier_load_banner_800.png";
import slide4Image768 from "../../img/artnovion_load_banner_800.png";

import slide1Image1280 from "../../img/Crestron_load_banner_1280.png";
import slide2Image1280 from "../../img/sonos_load_banner_1280.png";
import slide3Image1280 from "../../img/atelier_load_banner_1280.png";
import slide4Image1280 from "../../img/artnovion_load_banner_1280.png";

import slide1Image1920 from "../../img/Crestron_load_banner_1920.png";
import slide2Image1920 from "../../img/Sonos_load_banner_1920.png";
import slide3Image1920 from "../../img/Atelier_load_banner_1920.png";
import slide4Image1920 from "../../img/Artnovion_load_banner_1920.png";

const slides = [
  {
    id: "slide1",
    image: slide1ImageSm,
  },
  {
    id: "slide2",
    image: slide2ImageSm,
  },
  {
    id: "slide3",
    image: slide3ImageSm,
  },
  {
    id: "slide4",
    image: slide4ImageSm,
  },
];

const slides431 = [
  {
    id: "slide1",
    image: slide1Image431,
  },
  {
    id: "slide2",
    image: slide2Image431,
  },
  {
    id: "slide3",
    image: slide3Image431,
  },
  {
    id: "slide4",
    image: slide4Image431,
  },
];

const slides768 = [
  {
    id: "slide1",
    image: slide1Image768,
  },
  {
    id: "slide2",
    image: slide2Image768,
  },
  {
    id: "slide3",
    image: slide3Image768,
  },
  {
    id: "slide4",
    image: slide4Image768,
  },
];

const slides1280 = [
  {
    id: "slide1",
    image: slide1Image1280,
  },
  {
    id: "slide2",
    image: slide2Image1280,
  },
  {
    id: "slide3",
    image: slide3Image1280,
  },
  {
    id: "slide4",
    image: slide4Image1280,
  },
];

const slides1920 = [
  {
    id: "slide1",
    image: slide1Image1920,
  },
  {
    id: "slide2",
    image: slide2Image1920,
  },
  {
    id: "slide3",
    image: slide3Image1920,
  },
  {
    id: "slide4",
    image: slide4Image1920,
  },
];

const BannerCatalog = () => {
  const [selectedSlide, setSelectedSlide] = useState("slide1");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleChange = (event) => {
    setSelectedSlide(event.target.id);
  };
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     switch (selectedSlide) {
  //       case "slide1":
  //         setSelectedSlide("slide2");
  //         break;
  //       case "slide2":
  //         setSelectedSlide("slide3");
  //         break;
  //       case "slide3":
  //         setSelectedSlide("slide4");
  //         break;
  //       case "slide4":
  //         setSelectedSlide("slide5");
  //         break;
  //       case "slide5":
  //         setSelectedSlide("slide1");
  //         break;
  //       default:
  //         setSelectedSlide("slide1");
  //     }
  //   }, 3000); // змінюємо слайд кожні 3 секунди

  //   return () => clearInterval(interval);
  // }, [selectedSlide]);

  useEffect(() => {
    const interval = setInterval(() => {
      setSelectedSlide((prevSlide) => {
        switch (prevSlide) {
          case "slide1":
            return "slide2";
          case "slide2":
            return "slide3";
          case "slide3":
            return "slide4";
          case "slide4":
          default:
            return "slide1";
        }
      });
    }, 3000);

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      clearInterval(interval);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        {windowWidth > 430 && windowWidth < 768
          ? slides431.map((slide) => (
              <input
                key={slide.id}
                type="radio"
                name="point"
                id={slide.id}
                checked={selectedSlide === slide.id}
                onChange={handleChange}
              />
            ))
          : windowWidth >= 768 && windowWidth < 1280
          ? slides768.map((slide) => (
              <input
                key={slide.id}
                type="radio"
                name="point"
                id={slide.id}
                checked={selectedSlide === slide.id}
                onChange={handleChange}
              />
            ))
          : windowWidth >= 1280 && windowWidth < 1920
          ? slides1280.map((slide) => (
              <input
                key={slide.id}
                type="radio"
                name="point"
                id={slide.id}
                checked={selectedSlide === slide.id}
                onChange={handleChange}
              />
            ))
          : windowWidth >= 1920
          ? slides1920.map((slide) => (
              <input
                key={slide.id}
                type="radio"
                name="point"
                id={slide.id}
                checked={selectedSlide === slide.id}
                onChange={handleChange}
              />
            ))
          : slides.map((slide) => (
              <input
                key={slide.id}
                type="radio"
                name="point"
                id={slide.id}
                checked={selectedSlide === slide.id}
                onChange={handleChange}
              />
            ))}
        <div className={styles.slider}>
          {windowWidth > 430 && windowWidth < 768
            ? slides431.map((slide) => (
                <div
                  key={slide.id}
                  className={`${styles.slides} ${
                    selectedSlide === slide.id ? styles.active : ""
                  }`}
                  style={{ backgroundImage: `url(${slide.image})` }}
                ></div>
              ))
            : windowWidth >= 768 && windowWidth < 1280
            ? slides768.map((slide) => (
                <div
                  key={slide.id}
                  className={`${styles.slides} ${
                    selectedSlide === slide.id ? styles.active : ""
                  }`}
                  style={{ backgroundImage: `url(${slide.image})` }}
                ></div>
              ))
            : windowWidth >= 1280 && windowWidth < 1920
            ? slides1280.map((slide) => (
                <div
                  key={slide.id}
                  className={`${styles.slides} ${
                    selectedSlide === slide.id ? styles.active : ""
                  }`}
                  style={{ backgroundImage: `url(${slide.image})` }}
                ></div>
              ))
            : windowWidth >= 1920
            ? slides1920.map((slide) => (
                <div
                  key={slide.id}
                  className={`${styles.slides} ${
                    selectedSlide === slide.id ? styles.active : ""
                  }`}
                  style={{ backgroundImage: `url(${slide.image})` }}
                ></div>
              ))
            : slides.map((slide) => (
                <div
                  key={slide.id}
                  className={`${styles.slides} ${
                    selectedSlide === slide.id ? styles.active : ""
                  }`}
                  style={{ backgroundImage: `url(${slide.image})` }}
                ></div>
              ))}
        </div>

        <div className={styles.controls}>
          {windowWidth > 430 && windowWidth < 768
            ? slides431.map((slide) => (
                <label
                  key={slide.id}
                  htmlFor={slide.id}
                  className={
                    selectedSlide === slide.id ? styles.activeControl : ""
                  }
                ></label>
              ))
            : windowWidth >= 768 && windowWidth < 1280
            ? slides768.map((slide) => (
                <label
                  key={slide.id}
                  htmlFor={slide.id}
                  className={
                    selectedSlide === slide.id ? styles.activeControl : ""
                  }
                ></label>
              ))
            : windowWidth >= 1280 && windowWidth < 1920
            ? slides1280.map((slide) => (
                <label
                  key={slide.id}
                  htmlFor={slide.id}
                  className={
                    selectedSlide === slide.id ? styles.activeControl : ""
                  }
                ></label>
              ))
            : windowWidth >= 1920
            ? slides1920.map((slide) => (
                <label
                  key={slide.id}
                  htmlFor={slide.id}
                  className={
                    selectedSlide === slide.id ? styles.activeControl : ""
                  }
                ></label>
              ))
            : slides.map((slide) => (
                <label
                  key={slide.id}
                  htmlFor={slide.id}
                  className={
                    selectedSlide === slide.id ? styles.activeControl : ""
                  }
                ></label>
              ))}
        </div>
      </div>
    </div>
  );
};

export default BannerCatalog;
