import React, { useEffect } from "react";
import styles from "./CardItemNewsAll.module.scss";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  setIdNews,
  setIsTitle,
  setIsText,
  setIsData,
  setIsImg,
  setIsImg2,
  setIsImg3,
  setIsBrand
} from "../../store/slices/idNewsSlice";

const CardItemNewsAll = (props) => {
  const { title, text, img, img2, img3, brand, id, dataPost } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    const newDescribe = document.getElementById(`${id}`);
    newDescribe.innerHTML = text;

    const newDate = document.getElementById(`${title}`);
    newDate.innerHTML = dataPost;
  }, [text, dataPost, id, title]);

  return (
    <>
      <NavLink
        to={"/all-news/current-news"}
        style={({ isActive, isPending }) => {
          return {
            fontWeight: isActive ? "bold" : "",
            color: isPending ? "darkgrey" : "black",
            textDecoration: "none",
          };
        }}
      >
        <div
          key={id}
          className={styles.newspage_news}
          onClick={() => {
            dispatch(setIdNews({ id }));
            dispatch(setIsTitle({ title }));
            dispatch(setIsText({ text }));
            dispatch(setIsData({ dataPost }));
            dispatch(setIsImg({ img }));
            dispatch(setIsImg2({ img2 }));
            dispatch(setIsImg3({ img3 }));
            dispatch(setIsBrand({ brand }));
          }}
        >
          <img src={img} alt={title} className={styles.newspage_img} />
          <h1 className={styles.newspage_title}>{title}</h1>
          <p className={styles.newspage_text} id={`${id}`}></p>
          <p className={styles.newspage_date} id={`${title}`}></p>
        </div>
      </NavLink>
    </>
  );
};

CardItemNewsAll.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  img: PropTypes.string,
};
CardItemNewsAll.defaultProps = {
  title: "",
  text: "",
  img: "",
};

export default CardItemNewsAll;
