import { PureComponent } from "react";
import styles from "./NotFoundPage.module.scss";
import { Helmet } from "react-helmet";
import Footer from "../../components/Footer/Footer";
import error_1 from "../../img/Error 404/instagram.png";
import error_2 from "../../img/Error 404/facebook.png";
import error_3 from "../../img/Error 404/youtube.png";
import error_4 from "../../img/Error 404/linkedin.png";
import error_5 from "../../img/Error 404/tiktok.png";
import { NavLink } from "react-router-dom";

class NotFoundPage extends PureComponent {
  render() {
    return (
      <div className={styles.notFoundPageRoot}>
        <Helmet>
          <title>Not found</title>
          <meta
            name="description"
            content="На жаль, даної сторінки не існує, сформулюйте свій запит інакше | Page not found"
          />
        </Helmet>
        <div className={styles.notFoundPage}>
          <div className={styles.error}>
            <div className={styles.error_div}>
              <p className={styles.error_oops}>УПС!</p>
              <p className={styles.error_text}>
                Ми не змогли знайти сторінку, яку ви шукали
              </p>
              <div className={styles.error_num}>404</div>
              <NavLink
            to={"/"}
            style={({ isActive, isPending }) => {
              return {
                fontWeight: isActive ? "bold" : "",
                color: isPending ? "darkgrey" : "black",
                textDecoration: "none",
              };
            }}
          >
              <button className={styles.error_btn_back}>
                Повернутися назад
              </button>
              </NavLink>
              <div className={styles.error_div_share}>
                <NavLink
                  to="https://www.instagram.com/d8_engineering/"
                  target="_blank"
                >
                  <img className={styles.error_btn_instagram} src={error_1} alt="instagram error"/>
                </NavLink>
                <NavLink
                  to="https://www.facebook.com/d8engineer/"
                  target="_blank"
                >
                  <img className={styles.error_btn_facebook} src={error_2} alt="facebook error"/>
                </NavLink>
                <NavLink
                  to="https://www.youtube.com/@D8Engineering"
                  target="_blank"
                >
                  <img className={styles.error_btn_youtube} src={error_3} alt="youtube watch error" />
                </NavLink>
                <NavLink
                  to="https://ua.linkedin.com/company/d8-engineering"
                  target="_blank"
                >
                  <img className={styles.error_btn_linkedin} src={error_4} alt="linkedin look error" />
                </NavLink>
                <NavLink
                  to="https://www.tiktok.com/@d8.engineering"
                  target="_blank"
                >
                  <img className={styles.error_btn_tiktok} src={error_5} alt="tiktok watch error"/>
                </NavLink>
              </div>
            </div>
          </div>   
        </div>
        <div className={styles.notFoundFooter}>
        <Footer />
        </div>
      </div>
    );
  }
}

export default NotFoundPage;
