import { PureComponent } from "react";
import styles from "./OriginAcousticsPage.module.scss";
import { Helmet } from "react-helmet";
import Footer from "../../components/Footer/Footer";
import origin_1 from "../../img/Origin Acoustic/origin-1.png";
import origin_2 from "../../img/Origin Acoustic/origin-2.png";
import origin_3 from "../../img/Origin Acoustic/origin-3.png";
import origin_4 from "../../img/Origin Acoustic/origin-4.png";
import OurBrandsCatalog from "../../components/OurBrandsCatalog/OurBrandsCatalog";

class OriginAcousticsPage extends PureComponent {
  scrollto = () => {
    window.scrollTo(0, 0);
  };

  componentDidMount() {

    this.scrollto();

  }
  render() {
    return (
      <div className={styles.originAcPageRoot}>
        <Helmet>
          <title>Origin Acoustics</title>
          <meta
            name="description"
            content="Origin Acoustics hi-fi техніка | найсучасніші звукові рішення | найбільш вишукані архітектурні колонки| кришталево чистий звук| лідер в галузі завдяки інноваціям та досвіду"
          />
          <link
            rel="canonical"
            href="https://brands.d8.com.ua/origin-acoustics"
          />
        </Helmet>
        <div className={styles.originAcPage}>
          <div className={styles.origin}>
            <h1 className={styles.origin_article}>Про Origin Acoustics</h1>
            <div className={styles.origin_flex_1}>
              <img src={origin_1} className={styles.origin_photo1} alt="modern new speaker origin acoustics" />
              <div className={styles.origin_inside_1}>
                <h2 className={styles.origin_subarticle1}>Походження</h2>
                <p className={styles.origin_describe1}>
                  Кожен продукт, кожен процес Origin Acoustics бере свій початок
                  у магазині Hi-Fi техніки в Ріверсайді, штат Каліфорнія. Від
                  прискіпливої одержимості досконалістю до любові до чудового
                  звуку, ми з самого початку лідирували в галузі завдяки
                  інноваціям та досвіду.
                </p>
              </div>
            </div>

            <div className={styles.origin_flex_2}>
              <div className={styles.origin_inside_2}>
                <h2 className={styles.origin_subarticle2}>
                  «ТЕ, ЩО Є ЗАРАЗ - ЗАСТАРІЛО! РОСТІТЬ, РОЗВИВАЙТЕСЯ ТА
                  ДОСЯГАЙТЕ УСПІХУ ЩОДНЯ».
                </h2>
                <p className={styles.origin_describe2}>
                  -Джеремі Бурдхарт <br />
                  <br />
                  Засновник Origin Acoustics.
                </p>
              </div>
              <img src={origin_2} className={styles.origin_photo2} alt="Jeremy Budhart modern origin acoustics" />
            </div>

            <div>
              <h2 className={styles.origin_subarticle3}>Вся справа в звуці</h2>
              <p className={styles.origin_describe3}>
                Еволюційний процес розпочався у 1976 році, який привів до того,
                чим Origin Acoustics є сьогодні. Це не було пов'язано з грошима
                або початком бізнесу, все було пов'язано з музикою. Двоє
                талановитих людей любили музику і те, що вона робила з людьми
                навколо них. Їхньою метою було знайти спосіб поділитися цим
                досвідом з якомога більшою кількістю людей. <br />
                <br />
                Кен Хамфріс та Ед Хаазе відкрили невеликий магазин стереотехніки
                в Ріверсайді, штат Каліфорнія, в той час, коли аудіоіндустрія
                переживала неймовірну трансформацію. Вони побачили можливість
                принести в світ абсолютно новий рівень якості музики. Вони
                почали експериментувати з дизайном колонок і реакція місцевої
                громади була надзвичайно позитивною. <br />
                <br />
                Хоча ведення малого бізнесу в галузі, яку вони любили, було,
                безумовно, вигідним, вони збиралися зробити крок, який виведе їх
                на світову арену. Хоча прослуховування пари стереодинаміків у
                сімейній кімнаті було чудовим досвідом, люди хотіли
                насолоджуватися музикою в усьому домі. На початку 80-х Кен та Ед
                винайшли колонку, яку можна було вмонтовувати в стіну, і змінили
                обличчя індустрії. <br />
                <br />
                Хоча звичайні колонки все ще користувалися попитом, вбудовувані
                в стіну колонки стали вибором багатьох, оскільки кілька пар були
                встановлені в декількох кімнатах, створюючи буквально мелодію
                для життя кожного в домі. Знову ж таки, все було пов'язано з
                музикою. <br />
                <br />
                Попит споживачів змусив інші бренди колонок переглянути свої
                пропозиції, і до кого ж вони звернулися за рішенням? До
                винахідників концепції. З 1985 по 1994 рік Кен і Ед спроектували
                і виготовили мільйони настінних і стельових колонок для деяких з
                найбільших компаній в цьому бізнесі, зарекомендувавши себе як
                першокласне джерело архітектурної акустики.
              </p>
            </div>

            <div className={styles.origin_flex_3}>
              <img src={origin_3} className={styles.origin_photo3} alt="schema of speaker origin acoustics"/>
              <p className={styles.origin_describe4}>
                У 1994 році вони випустили власні колонки під власною торговою
                маркою з назвою SpeakerCraft®. Стрімке зростання бренду
                відбулося не в останню чергу завдяки людині, яка стала легендою
                індустрії. Джеремі Буркхардт, молодий підприємець, чий драйв і
                бачення вивели компанію на лідируючі позиції в індустрії. Понад
                десять років SpeakerCraft домінувала у сфері інсталяції на
                замовлення та встановлювала стандарти технічного розвитку та
                інновацій. <br />
                <br />
                Хоча пізніше компанія була придбана великим конгломератом, а
                Джеремі відійшов від справ, в його серці залишилося палке
                бажання винайти новий продукт і процес. Возз'єднавшись зі своїми
                першими партнерами, Кеном та Едом, вони почали обговорювати
                можливості. <br />
                <br />
                Origin Acoustics стартувала під неймовірні фанфари в 2014 році з
                лінійкою колонок, які в черговий раз переосмислили категорію. З
                чистого аркуша команда інженерів застосувала десятиліття досвіду
                і перезавантажила стандарт того, що люди можуть очікувати від
                архітектурних колонок. З моменту запуску Origin Acoustics стала
                другим найпопулярнішим брендом у цій категорії, згідно з
                рейтингом CEPro Brand Analysis 2019, провідним галузевим
                виданням, опублікованим у 2019 році.
              </p>
            </div>

            <div className={styles.origin_flex_4}>
              <img src={origin_4} className={styles.origin_photo4} alt="speaker origin acoustics" />
              <div className={styles.origin_inside_4}>
                <h2 className={styles.origin_subarticle5}>Чисте полотно</h2>
                <p className={styles.origin_describe5}>
                  Компанія Origin Acoustics розпочала свою діяльність у 2014
                  році і перевернула індустрію з ніг на голову. Починаючи з
                  нуля, наша команда створила найбільш вишукану архітектурну
                  колонку з усіх коли-небудь створених. <br />
                  <br />
                  Origin продовжує рости, розвиватися та впроваджувати
                  інновації, виводячи на ринок найсучасніші звукові рішення,
                  доступні будь-де. Коли ми зазираємо в дуже світле майбутнє,
                  технології змінюватимуться, а нові ідеї ставатимуть
                  реальністю. І попри це, наша мета залишатиметься такою ж
                  кришталево чистою, як і з самого початку. Йдеться про музику.
                </p>
              </div>
            </div>
            <OurBrandsCatalog />
          </div>

          <Footer />
        </div>
      </div>
    );
  }
}

export default OriginAcousticsPage;
