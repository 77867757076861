import React from 'react';
import styles from "./FilterCatalogTablet.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { setCrestronProduct, setSiProduct, setOriginProduct, setSonosProduct, setUnitechProduct, setArtnovionProduct, setAtelierProduct, setReset} from "../../store/slices/filterSlice";


function FilterCatalogTablet() {

  const dispatch = useDispatch();
  const chosenCategoryOrBrand = useSelector((store) => store.filters.chosenCategoryOrBrand);
  const crestronProduct = useSelector((store) => store.filters.crestronProduct);
  const siProduct = useSelector((store) => store.filters.siProduct);
  const originProduct = useSelector((store) => store.filters.originProduct);
  const sonosProduct = useSelector((store) => store.filters.sonosProduct);
  const unitechProduct = useSelector((store) => store.filters.unitechProduct);
  const artnovionProduct = useSelector((store) => store.filters.artnovionProduct);
  const atelierProduct = useSelector((store) => store.filters.atelierProduct);
      
      return ( 
        <form className={styles.catalog_tablet_div_form}>
          <div className={styles.catalog_tablet_div}>
            {chosenCategoryOrBrand === 'automatization'
              ? <label className={styles.catalog_tablet_label_checkbox}>
                  <span className={styles.catalog_tablet_label_text}>Crestron</span>
                  <input 
                    type="checkbox"
                    name="Crestron"
                    checked={crestronProduct} 
                    onChange={() => dispatch(setCrestronProduct(!crestronProduct))} />
                </label>
              : undefined    
            }

            { chosenCategoryOrBrand === 'cinema'
              ? <label className={styles.catalog_tablet_label_checkbox}>
                  <span className={styles.catalog_tablet_label_text}>SI</span>
                  <input 
                    type="checkbox"
                    name="SI" 
                    checked={siProduct}
                    onChange={() => dispatch(setSiProduct(!siProduct))} />
                </label>
              : undefined
            }              
            
            { chosenCategoryOrBrand === 'acoustic'
              ? <label className={styles.catalog_tablet_label_checkbox}>
                  <span className={styles.catalog_tablet_label_text}>Origin Acoustics</span>
                  <input 
                    type="checkbox"
                    name="Origin"
                    checked={originProduct}                                     
                    onChange={() => dispatch(setOriginProduct(!originProduct))} />
                </label>                     
              : undefined
            }
            
            { chosenCategoryOrBrand === 'acoustic'
              ? <label className={styles.catalog_tablet_label_checkbox}>
                  <span className={styles.catalog_tablet_label_text}>Sonos</span>
                  <input 
                    type="checkbox"
                    name="Sonos"
                    checked={sonosProduct}                                     
                    onChange={() => dispatch(setSonosProduct(!sonosProduct))} />
                </label>
              : undefined
            }
            
            { chosenCategoryOrBrand === 'cinema'
              ? <label className={styles.catalog_tablet_label_checkbox}>
                  <span className={styles.catalog_tablet_label_text}>Unitech Systems</span>
                  <input 
                    type="checkbox"
                    name="Unitech"
                    checked={unitechProduct}                                     
                    onChange={() => dispatch(setUnitechProduct(!unitechProduct))} />
                </label>
              : undefined
            }
            
            { chosenCategoryOrBrand === 'cinema'
              ? <label className={styles.catalog_tablet_label_checkbox}>
                  <span className={styles.catalog_tablet_label_text}>Artnovion</span>
                  <input 
                    type="checkbox"
                    name="Artnovion"
                    checked={artnovionProduct}                                     
                    onChange={() => dispatch(setArtnovionProduct(!artnovionProduct))} />
                </label>
               : undefined
              }
              
              { chosenCategoryOrBrand === 'furniture'
                ? <label className={styles.catalog_tablet_label_checkbox}>
                    <span className={styles.catalog_tablet_label_text}>Atelier Luxus</span>
                    <input 
                      type="checkbox"
                      name="Atelier"
                      checked={atelierProduct}                                     
                      onChange={() => dispatch(setAtelierProduct(!atelierProduct))} />
                  </label>
                : undefined
              }

            <button 
              type="button" 
              onClick={() => dispatch(setReset(false))} 
              className={`${crestronProduct || siProduct || originProduct || sonosProduct || unitechProduct || artnovionProduct || atelierProduct ? styles.catalog_tablet_btn_reset : styles.catalog_tablet_btn_reset_NV}`}>
              Очистити
            </button> 
          </div>
      </form>
    );

  
}

export default FilterCatalogTablet;
