import styles from "./Modal.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { setIsOpen } from "../../store/slices/modalSlice";
import { setIsFlagHead } from "../../store/slices/flagHeadSlice.js";
import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
import NextArrow from "../../img/Next_arrow_Modal.png";

const ModalMenu = () => {
  const isOpen = useSelector((store) => store.modal.isOpen);
  const isFlag = useSelector((store) => store.flag.isFlag);
  const dispatch = useDispatch();

  const closeModal = () => {
    var elem_1 = document.getElementById("main");
    dispatch(setIsFlagHead(false));

    const timeout_1 = setTimeout(() => {
      elem_1.classList.add(`${styles.root_fade}`);
    }, 0);
    const timeout = setTimeout(() => {
      elem_1.classList.remove(`${styles.root_fade}`);
    }, 1000);

    const timeout_2 = setTimeout(() => {
      dispatch(setIsOpen(false));
    }, 700);

    return () => {
      clearTimeout(timeout);
      clearTimeout(timeout_1);
      clearTimeout(timeout_2);
    };
  };

  useEffect(() => {
    if (isFlag) {
      var elem_1 = document.getElementById("main");

      const timeout_1 = setTimeout(() => {
        elem_1.classList.add(`${styles.root_fade}`);
      }, 0);
      const timeout = setTimeout(() => {
        elem_1.classList.remove(`${styles.root_fade}`);
        console.log(elem_1);
      }, 1000);
      return () => {
        clearTimeout(timeout);
        clearTimeout(timeout_1);
      };
    }
  }, [isFlag]);

  if (!isOpen) {
    return null;
  }

  return (
    <div className={styles.root} id="main">
      <div className={styles.content}>
        <div className={styles.buttonContainer}>
          <NavLink
            to={"/"}
            style={({ isActive, isPending }) => {
              return {
                fontWeight: isActive ? "bold" : "",
                color: isPending ? "darkgrey" : "white",
                textDecoration: "none",
              };
            }}
          >
            <span className={styles.modalMenu_main} onClick={closeModal}>
              Головна
              <img
                src={NextArrow}
                alt="Next aroow Icon"
                className={styles.modalMenu_nextArrow__main}
              ></img>
            </span>
          </NavLink>
          <Link to={"/all-news"} style={{ textDecoration: "none" }}>
            <span className={styles.modalMenu_news} onClick={closeModal}> 
              Новини
              <img
                src={NextArrow}
                alt="Next aroow Icon"
                className={styles.modalMenu_nextArrow__news}
              ></img>
            </span>
          </Link>
          <NavLink
            to={"/brands"}
            style={({ isActive, isPending }) => {
              return {
                fontWeight: isActive ? "bold" : "",
                color: isPending ? "darkgrey" : "black",
                textDecoration: "none",
              };
            }}
          >
            <span className={styles.modalMenu_brands} onClick={closeModal}>
              Бренди
              <img
                src={NextArrow}
                alt="Next aroow Icon"
                className={styles.modalMenu_nextArrow__brands}
              ></img>
            </span>
          </NavLink>
          <NavLink
            to={"/catalog"}
            style={({ isActive, isPending }) => {
              return {
                fontWeight: isActive ? "bold" : "",
                color: isPending ? "darkgrey" : "black",
                textDecoration: "none",
              };
            }}
          >
            <span className={styles.modalMenu_catalog} onClick={closeModal}>
              Каталог
              <img
                src={NextArrow}
                alt="Next arrow Icon"
                className={styles.modalMenu_nextArrow__catalog}
              ></img>
            </span>
          </NavLink>
          <NavLink
            to={"/contacts"}
            style={({ isActive, isPending }) => {
              return {
                fontWeight: isActive ? "bold" : "",
                color: isPending ? "darkgrey" : "black",
                textDecoration: "none",
              };
            }}
          >
            <span className={styles.modalMenu_contacts} onClick={closeModal}>
              Контакти
              <img
                src={NextArrow}
                alt="Next aroow Icon"
                className={styles.modalMenu_nextArrow__contacts}
              ></img>
            </span>
          </NavLink>
        </div>
      </div>
      <div onClick={closeModal} className={styles.background} />
    </div>
  );
};

export default ModalMenu;
