import { configureStore } from "@reduxjs/toolkit";
import newsReducer from "./slices/newsSlice";
import modalReducer from "./slices/modalSlice";
import productsReducer from "./slices/productsSlice";
import filterReducer from "./slices/filterSlice";
import flagHeadReducer from "./slices/flagHeadSlice";
import flagReducer from "./slices/flagSlice";
import  filterNewsReducer from "./slices/filterSliceNews";
import  modalFiltrNewsReducer from "./slices/modalFiltrNewsSlice";
import  flagNewsReducer from "./slices/flagNewsSlice";
import  idNewsReducer from "./slices/idNewsSlice";
import  modalFiltrDeviceReducer from "./slices/modalFiltrDeviceSlice";
import  flagDeviceReducer from "./slices/flagDeviceSlice";
import  idDeviceReducer from "./slices/idDeviceSlice";
import itemsOurBrandsReducer from "./slices/itemsOurBrands";

const store = configureStore({
  reducer: {
    news: newsReducer,
    modal: modalReducer,
    products: productsReducer,
    filters: filterReducer, 
    flagHead: flagHeadReducer,
    flag: flagReducer,
    filterNews: filterNewsReducer,
    modalNews: modalFiltrNewsReducer,
    flagNews: flagNewsReducer,
    idNews: idNewsReducer,
    modalDevice: modalFiltrDeviceReducer,
    flagDevice: flagDeviceReducer,
    idDevice: idDeviceReducer,
    itemsOurBrands: itemsOurBrandsReducer,
  },
});

export default store;
