import React, { useEffect, useMemo } from "react";
import styles from "./OurBrandsCatalog.module.scss";
import DeviceItem from "../DeviceItem/DeviceItem";
import { useDispatch, useSelector } from "react-redux";
import { fetchProduct } from "../../store/slices/productsSlice";
import search_icon from "../../img/Catalog/search.png";
import { setSearchVal } from "../../store/slices/filterSlice";

function OurBrandsCatalog() {

  const scrollto = () => {
    window.scrollTo(0, 0);
  };

  const dispatch = useDispatch();
  const products = useSelector((store) => store.products.data);

  const chosenBrand = useSelector((store) => store.itemsOurBrands.chosenBrand);
  const searchVal   = useSelector((store) => store.filters.searchVal);

  const filteredCategory = products.filter(
    (product) =>
      (chosenBrand === "crestron"       && product.brand    === "crestron")       ||
      (chosenBrand === "si"             && product.brand    === "si")             ||
      (chosenBrand === "origin"         && product.brand    === "origin")         ||
      (chosenBrand === "sonos"          && product.brand    === "sonos")          ||
      (chosenBrand === "unitech"        && product.brand    === "unitech")        ||
      (chosenBrand === "artnovion"      && product.brand    === "artnovion")      ||
      (chosenBrand === "atelier"        && product.brand    === "atelier")        ||
      (chosenBrand === "furniture"      && product.brand    === "atelier")
  );

  filteredCategory.sort((a, b) => {
    const aIsAvailable = a.status.includes('available');
    const bIsAvailable = b.status.includes('available');
    
    if (aIsAvailable && !bIsAvailable) {
      return -1;
    }
    if (!aIsAvailable && bIsAvailable) {
      return 1;
    }
    return 0;
  });

  const fetchData = useMemo(() => () => {
    dispatch(fetchProduct());
  }, [dispatch]);


  useEffect(() => {
    scrollto();

      fetchData();
 

  }, [fetchData]);

  const filterBySearch = filteredCategory.filter((item) => {
    return item.title.toLowerCase().includes(searchVal.toLowerCase());
  });
  
  return (
    <div className={styles.ourBrandsCatalogRoot}>
      <div className={styles.ourBrandsCatalogRootPage}>
        <h1 className={styles.ourBrandsCatalogRootpage_head}>Список товарів</h1>

        <div>
          <input
            type="text"
            name="finder"
            placeholder="назва продукту"
            onChange={(e) => dispatch(setSearchVal(e.target.value))}
            className={styles.catalogpage_finder}
          />
          <img
            className={styles.catalogpage_search}
            src={search_icon}
            alt="Search Icon"
          />

          {searchVal !== "" ? (
            <div className={styles.ourBrandsCatalog_div}>
              {filterBySearch.map((item) => (
                <DeviceItem
                  title={item.title}
                  img={item.img}
                  id={item._id}
                  key={item._id}
                  status={item.status}
                  text={item.describe}
                  img1={item.add_img1}
                  img2={item.add_img2}
                  img3={item.add_img3}
                  img4={item.add_img4}
                  img5={item.add_img5}
                  brand={item.brand}
                />
              ))}
            </div>
          ) : (
            <div className={styles.ourBrandsCatalog_div}>
              {filteredCategory.map((item) => (
                <DeviceItem
                  title={item.title}
                  img={item.img}
                  id={item._id}
                  key={item._id}
                  status={item.status}
                  text={item.describe}
                  img1={item.add_img1}
                  img2={item.add_img2}
                  img3={item.add_img3}
                  img4={item.add_img4}
                  img5={item.add_img5}
                  brand={item.brand}
                />
              ))}
            </div>
          )}  
        </div>
      </div>
    </div>
  );
}

export default OurBrandsCatalog;
