import React, { useEffect } from "react";
import styles from "./FilterCatalogPhone.module.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  setOpenFilter,
  setCrestronProduct,
  setSiProduct,
  setOriginProduct,
  setSonosProduct,
  setUnitechProduct,
  setArtnovionProduct,
  setAtelierProduct,
  setReset,
} from "../../store/slices/filterSlice";
import { setIsOpen } from "../../store/slices/modalFiltrDeviceSlice";

function FilterCatalogPhone() {
  const dispatch = useDispatch();
  const chosenCategoryOrBrand = useSelector(
    (store) => store.filters.chosenCategoryOrBrand
  );
  const crestronProduct = useSelector((store) => store.filters.crestronProduct);
  const siProduct = useSelector((store) => store.filters.siProduct);
  const originProduct = useSelector((store) => store.filters.originProduct);
  const sonosProduct = useSelector((store) => store.filters.sonosProduct);
  const unitechProduct = useSelector((store) => store.filters.unitechProduct);
  const artnovionProduct = useSelector(
    (store) => store.filters.artnovionProduct
  );
  const atelierProduct = useSelector((store) => store.filters.atelierProduct);

  const isOpen = useSelector((store) => store.modalDevice.isOpen);
  const isFlag = useSelector((store) => store.flagDevice.isFlag);

  const closeModal = () => {
    var elem_1 = document.getElementById("main");
    const timeout_1 = setTimeout(() => {
      elem_1.classList.add(`${styles.root_fade}`);
    }, 0);
    const timeout = setTimeout(() => {
      elem_1.classList.remove(`${styles.root_fade}`);
    }, 1000);

    const timeout_2 = setTimeout(() => {
      dispatch(setIsOpen(false));
    }, 700);

    return () => {
      clearTimeout(timeout);
      clearTimeout(timeout_1);
      clearTimeout(timeout_2);
    };
  };

  useEffect(() => {
    if (isFlag) {
      var elem_1 = document.getElementById("main");

      const timeout_1 = setTimeout(() => {
        elem_1.classList.add(`${styles.root_fade}`);
      }, 0);
      const timeout = setTimeout(() => {
        elem_1.classList.remove(`${styles.root_fade}`);
        console.log(elem_1);
      }, 1000);
      return () => {
        clearTimeout(timeout);
        clearTimeout(timeout_1);
      };
    }
  }, [isFlag]);

  if (!isOpen) {
    return null;
  }

  return (
    <div className={styles.catalog_div_check} id="main">
      <div className={styles.catalog_div_set_reset}>
        <button
          type="button"
          onClick={() => dispatch(setReset(false))}
          className={`${
            crestronProduct ||
            siProduct ||
            originProduct ||
            sonosProduct ||
            unitechProduct ||
            artnovionProduct ||
            atelierProduct
              ? styles.catalog_btn_reset
              : styles.catalog_btn_reset_NV
          }`}
        >
          Очистити
        </button>
        <button
          type="button"
          className={styles.catalog_btn_set}
          onClick={() => {
            dispatch(setOpenFilter(false));
            closeModal();
          }}
        >
          Готово
        </button>
      </div>

      <form className={styles.catalog_div_form}>
        <div className={styles.catalog_div_line} />
        <div className={styles.catalog_div_checkbox}>
          {chosenCategoryOrBrand === "automatization" ? (
            <label className={styles.catalog_label_checkbox}>
              <span className={styles.catalog_label_text}>Crestron</span>
              <input
                type="checkbox"
                name="Crestron"
                checked={crestronProduct}
                onChange={() => dispatch(setCrestronProduct(!crestronProduct))}
              />
            </label>
          ) : (
            <div />
          )}

          {chosenCategoryOrBrand === "cinema" ? (
            <label className={styles.catalog_label_checkbox}>
              <span className={styles.catalog_label_text}>SI</span>
              <input
                type="checkbox"
                name="SI"
                checked={siProduct}
                onChange={() => dispatch(setSiProduct(!siProduct))}
              />
            </label>
          ) : (
            <div />
          )}

          {chosenCategoryOrBrand === "acoustic" ? (
            <label className={styles.catalog_label_checkbox}>
              <span className={styles.catalog_label_text}>
                Origin Acoustics
              </span>
              <input
                type="checkbox"
                name="Origin"
                checked={originProduct}
                onChange={() => dispatch(setOriginProduct(!originProduct))}
              />
            </label>
          ) : (
            <div />
          )}

          {chosenCategoryOrBrand === "acoustic" ? (
            <label className={styles.catalog_label_checkbox}>
              <span className={styles.catalog_label_text}>Sonos</span>
              <input
                type="checkbox"
                name="Origin"
                checked={sonosProduct}
                onChange={() => dispatch(setSonosProduct(!sonosProduct))}
              />
            </label>
          ) : (
            <div />
          )}

          {chosenCategoryOrBrand === "cinema" ? (
            <label className={styles.catalog_label_checkbox}>
              <span className={styles.catalog_label_text}>Unitech Systems</span>
              <input
                type="checkbox"
                name="Unitech"
                checked={unitechProduct}
                onChange={() => dispatch(setUnitechProduct(!unitechProduct))}
              />
            </label>
          ) : (
            <div />
          )}

          {chosenCategoryOrBrand === "cinema" ? (
            <label className={styles.catalog_label_checkbox}>
              <span className={styles.catalog_label_text}>Artnovion</span>
              <input
                type="checkbox"
                name="Artnovion"
                checked={artnovionProduct}
                onChange={() =>
                  dispatch(setArtnovionProduct(!artnovionProduct))
                }
              />
            </label>
          ) : (
            <div />
          )}

          {chosenCategoryOrBrand === "furniture" ? (
            <label className={styles.catalog_label_checkbox}>
              <span className={styles.catalog_label_text}>Atelier Luxus</span>
              <input
                type="checkbox"
                name="Atelier"
                checked={atelierProduct}
                onChange={() => dispatch(setAtelierProduct(!atelierProduct))}
              />
            </label>
          ) : (
            <div />
          )}
        </div>
      </form>
    </div>
  );
}

export default FilterCatalogPhone;
