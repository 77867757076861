import { createSlice } from "@reduxjs/toolkit"; // with immer and produce

const flagSlice = createSlice({
    name: 'flag',
    initialState: {
        isFlag: false
    },
    reducers: {
        setIsFlag: (state, action) => {
            state.isFlag = action.payload;
        },

    }
});

export const { setIsFlag } = flagSlice.actions;

export default flagSlice.reducer;