import { createSlice } from "@reduxjs/toolkit"; // with immer and produce

const itemsOurBrands = createSlice({
  name: "itemSlice",
  initialState: {
    chosenBrand: "crestron",
  },
  reducers: {
    setCategory: (state, action) => {
      state.chosenBrand = action.payload;
    },

    setReset: (state, action) => {
      state.chosenBrand = action.payload;
    },
  },
});

export const { setCategory, setReset } = itemsOurBrands.actions;

export default itemsOurBrands.reducer;
