import React from "react";
import styles from "./DeviceItem.module.scss";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  setIdDevice,
  setIsTitle,
  setIsText,
  setIsImg,
  setIsImg1,
  setIsImg2,
  setIsImg3,
  setIsImg4,
  setIsImg5,
  setIsBrand,
  setIsStatus,
} from "../../store/slices/idDeviceSlice";

const DeviceItem = (props) => {
  const { title, img, id, status, text, img1, img2, img3, img4, img5, brand } = props;

  const dispatch = useDispatch();


  return (
    <>
      <NavLink
        to={"/catalog/chosen-device"}
        style={({ isActive, isPending }) => {
          return {
            fontWeight: isActive ? "bold" : "",
            color: isPending ? "darkgrey" : "black",
            textDecoration: "none",
          };
        }}
      >
        <div
          key={id}
          className={styles.scatalog_product}
          onClick={() => {
            dispatch(setIdDevice({ id }));
            dispatch(setIsTitle({ title }));
            dispatch(setIsText({ text }));
            dispatch(setIsImg({ img }));
            dispatch(setIsImg1({ img1 }));
            dispatch(setIsImg2({ img2 }));
            dispatch(setIsImg3({ img3 }));
            dispatch(setIsImg4({ img4 }));
            dispatch(setIsImg5({ img5 }));
            dispatch(setIsBrand({ brand }));
            dispatch(setIsStatus({ status }));
          }}
        >
          <div className={styles.scatalog_bg}>
            <img src={img} alt={title} className={styles.scatalog_img} />
          </div>
          <div className={styles.scatalog_title_div}>
            <h1 className={styles.scatalog_title}>{title}</h1>
            <div className={styles.catalog_status_div}>
              {status === "available" ? (
                <p className={styles.catalog_status_available}>В наявності</p>
              ) : (
                <p className={styles.catalog_status_order}>Під замовлення</p>
              )}
            </div>
          </div>
        </div>
      </NavLink>
    </>
  );
};

DeviceItem.propTypes = {
  title: PropTypes.string,
  img: PropTypes.string,
};
DeviceItem.defaultProps = {
  title: "",
  img: "",
};

export default DeviceItem;
