import { PureComponent } from "react";
import styles from "./AtelierLuxusPage.module.scss";
import { Helmet } from "react-helmet";
import Footer from "../../components/Footer/Footer";
import atelier_1 from '../../img/Atelier Luxus/atelier-1.png';
import atelier_2 from '../../img/Atelier Luxus/atelier-2.png';
import atelier_3 from '../../img/Atelier Luxus/atelier-3.png';
import atelier_4 from '../../img/Atelier Luxus/atelier-4.png';
import { NavLink } from "react-router-dom";
import OurBrandsCatalog from "../../components/OurBrandsCatalog/OurBrandsCatalog";


class AtelierLuxusPage extends PureComponent {
  scrollto = () => {
    window.scrollTo(0, 0);
  };

  componentDidMount() {

    this.scrollto();

  }
  render() {
    return (
      <div className={styles.atelLuxPageRoot}>
        <Helmet>
          <title>Atelier LUXUS</title>
          <meta
            name="description"
            content="Atelier LUXUS - дизайнерські електроприлади та фурнітура для найелегантніших інтер'єрів. З максимальною рішучістю пропонувати надзвичайне. Чудове. Найкраще. Завжди. Міцні дорогоцінні метали. Пристрасть до краси та досконалості в мистецтві жити народжує вироби Atelier LUXUS, які є справжніми об'єктами бажання "
          />
          <link rel="canonical" href="https://brands.d8.com.ua/atelier-luxus" />
        </Helmet>
        <div className={styles.atelLuxPage}>
      

        <div className={styles.atelier}>
        <h1 className={styles.atelier_article}>
          Про Atelier Luxus
        </h1>
        <div className={styles.atelier_div_1}>
          <img src={atelier_1} className={styles.atelier_photo_1} alt="atelier luxus design watch look"/>
          <div className={styles.atelier_inside_1}>
            <h2 className={styles.atelier_subarcticle_1}>
              Найкраще - завжди
            </h2>
            <p className={styles.atelier_describe_1}>
              Протягом останніх 25 років Atelier Luxus розробляє дизайнерські електроприлади та фурнітуру для найелегантніших інтер'єрів. З максимальною рішучістю пропонувати надзвичайне. Чудове. Найкраще. Завжди.
            </p>
            <NavLink
            to="https://www.atelierluxus.com/"
            target="_blank"
            className={styles.atelier_a_1}
          >
              <button className={styles.atelier_btn_1}>
                Дізнатись більше
              </button>
            </NavLink>
          </div>
        </div>

        <div className={styles.atelier_div_2}>
          <img src={atelier_2} className={styles.atelier_photo_2} alt="button pulse design metal"/>
          <div className={styles.atelier_inside_2}>
            <h2 className={styles.atelier_subarcticle_2}>
              Увага до деталей
            </h2>
            <p className={styles.atelier_describe_2}>
              Завдяки вишуканим деталям, міцному дорогоцінному металу та неперевершеній формі, якість Atelier Luxus виражається як в естетиці, так і у відчуттях. Створюючи тактильний, візуальний досвід, унікальний за дизайном, винятковий за виконанням. <br/><br/>Такий підхід дозволяє інтегрувати Atelier Luxus як в звичайні електричні схеми, так і в складні комплексні системи автоматизації простору.
            </p>
          </div>
        </div>

        <div className={styles.atelier_div_3}>
          <img src={atelier_3} className={styles.atelier_photo_3} alt="manufacture design expensive meal"/>
          <div className={styles.atelier_inside_3}>
            <h2 className={styles.atelier_subarcticle_3}>
              Паралельні світи
            </h2>
            <p className={styles.atelier_describe_3}>
              «Ми знаходимося на перехресті трьох різних світів і поєднуємо їхні сильні сторони для створення унікальних виробів», - пояснює Начі Арель. <br/><br/>«Технологія забезпечує функціональність кожного виробу і прокладає шлях до найсучасніших  можливостей. Дизайн визначає концепцію кожного вимикача або розетки, з підходом, який є одночасно сучасним і позачасовим. Майстерність гарантує, що кожен компонент буде ідеально оброблений, використовуючи виняткові матеріали та традиційні техніки, які застосовуються протягом багатьох років».
            </p>
          </div>
        </div>

        <div className={styles.atelier_div_4}>
          <img src={atelier_4} className={styles.atelier_photo_4} alt="design metal exlusive"/>
          <div className={styles.atelier_inside_4}>
            <h2 className={styles.atelier_subarcticle_4}>
              Індивідуальне світло
            </h2>
            <p className={styles.atelier_describe_4}>
              Дизайн моделей, виготовлення деталей, останні штрихи та збірка здійснюються в наших майстернях у Льєжі та Шарлеруа. На технічному рівні все також перевіряється на місці, щоб забезпечити оптимальну якість кожного готового виробу. На 100% бельгійський продукт, виготовлений за індивідуальним замовленням, який адаптований до потреб клієнта. <br/><br/>Крім того, Atelier Luxus поважає всі побажання щодо оздоблення - що є дуже важливим аспектом, коли працюєш у вищому сегменті ринку - навіть якщо вони спочатку не пропонуються в нашому каталозі.
            </p>
          </div>
        </div>
        <p className={styles.describe_footer}>
          Пристрасть до краси та досконалість в мистецтві жити народжує вироби Atelier Luxus, які є справжніми об'єктами бажання.
        </p>
        <OurBrandsCatalog />
      </div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default AtelierLuxusPage;
