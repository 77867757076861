import { PureComponent } from "react";
import styles from "./ArtnovionPage.module.scss";
import { Helmet } from "react-helmet";
import Footer from "../../components/Footer/Footer";
import artnovion_1 from "../../img/Artnovion/artnovion-1.png";
import artnovion_2 from "../../img/Artnovion/artnovion-2.png";
import artnovion_3 from "../../img/Artnovion/artnovion-3.png";
import artnovion_4 from "../../img/Artnovion/artnovion-4.png";
import OurBrandsCatalog from "../../components/OurBrandsCatalog/OurBrandsCatalog";

class ArtnovionPage extends PureComponent {
  scrollto = () => {
    window.scrollTo(0, 0);
  };

  componentDidMount() {
    this.scrollto();
  }
  render() {
    return (
      <div className={styles.artNovPageRoot}>
        <Helmet>
          <title>Artnovion</title>
          <meta
            name="description"
            content="Artnovion керує пристрасть. Пристрасть до акустики, продуктивності і до забезпечення дивовижного клієнтського досвіду. Унікальна колекція продуктів, що розширюють межі акустичної інженерії. Без шкоди для найкращої продуктивності використовують менше матеріалів у продуктах, щоб зменшити вплив на навколишнє середовище "
          />
          <link rel="canonical" href="https://brands.d8.com.ua/artnovion" />
        </Helmet>
        <div className={styles.artNovPage}>
          <div className={styles.artnovion}>
            <h1 className={styles.artnovion_article}>Про Artnovion</h1>
            <img src={artnovion_1} className={styles.artnovion_photo_1} alt="design art" />
            <p className={styles.artnovion_describe}>
              Artnovion керує пристрасть. Пристасть до акустики, пристрасть до
              продуктивності і вічна пристрасть - забезпечувати дивовижний
              клієнтський досвід. З талановитою командою вони наполегливо
              працюють над розширення меж акустичної інженерії, щоб створити
              унікальну колекцію продуктів, що задають тенденції та мають високі
              експлуатаційні характеристики та гарний, сучасний вигляд.
            </p>
            <div className={styles.artnovion_div_1}>
              <img src={artnovion_2} className={styles.artnovion_photo_2}alt="artnovion passion" />
              <div className={styles.artnovion_inside_1}>
                <h2 className={styles.artnovion_subarcticle_1}>
                  Пристасть до акустики
                </h2>
                <p className={styles.artnovion_describe_1}>
                  Використовуючи технології, що лежать в основі міцної мережі
                  партнерів, Artnovion поєднує найкращі сторони дизайну,
                  інженерії та обслуговування, пропонуючи широкий вибір
                  продуктів, які поєднують в собі високі акустичні
                  характеристики та унікальні візуальні ефекти. <br />
                  <br />
                  «Ми такі ж динамічні, як і наш ринок, і завжди тримаємо
                  клієнта в центрі уваги, ми постійно адаптуємося в пошуках
                  постійного вдосконалення і пропонуємо спрощений користувацький
                  досвід: від продажів, управління проектами до доставки».
                </p>
              </div>
            </div>

            <div className={styles.artnovion_div_2}>
              <img src={artnovion_3} className={styles.artnovion_photo_3} alt="ideal proportional"/>
              <div className={styles.artnovion_inside_2}>
                <h2 className={styles.artnovion_subarcticle_2}>
                  Ідеальне співвідношення
                </h2>
                <p className={styles.artnovion_describe_2}>
                  Унікальна конструкція створює ізольовані повітряні порожнини,
                  які не тільки оптимізують перевезення, але й забезпечують на
                  20% кращі акустичні характеристики. <br />
                  <br />
                  Унікальна каркасна архітектура з унікальною інтегрованою
                  механічною фіксацією. <br />
                  <br />
                  Нова архітектура рами забезпечує безпрецедентну стабільність у
                  сегменті. <br />
                  <br />
                  Нова архітектура рами забезпечує стабільність розмірів та
                  ідеальну збірку між блоками.
                </p>
              </div>
            </div>

            <div className={styles.artnovion_div_3}>
              <img src={artnovion_4} className={styles.artnovion_photo_4} alt="bio less waste"/>
              <div className={styles.artnovion_inside_3}>
                <h2 className={styles.artnovion_subarcticle_3}>
                  Менше матеріалу. Менший вплив на навколишнє середовище
                </h2>
                <p className={styles.artnovion_describe_3}>
                  Без шкоди для найкращої продуктивності, ми взяли на себе
                  зобов'язання використовувати менше матеріалів у наших
                  продуктах, щоб зменшити наш вплив на навколишнє середовище.{" "}
                  <br />
                  <br />
                  Екологічна свідомість є основною цінністю для нашої компанії.
                  Саме тому ми взяли на себе зобов'язання зменшити наш
                  вуглецевий слід і продовжувати оптимізувати кожен аспект нашої
                  діяльності для досягнення екологічної стійкості.
                </p>
              </div>
            </div>
            <OurBrandsCatalog />
          </div>

          <Footer />
        </div>
      </div>
    );
  }
}

export default ArtnovionPage;
