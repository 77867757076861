import { createSlice } from "@reduxjs/toolkit"; // with immer and produce

const idDeviceSlice = createSlice({
    name: 'idDevice',
    initialState: {
        idDevice: {"_id":242},
        isTitle: {"title":"Оберіть девайс"},
        isText: {"text":"Будь ласка, поверніться до сторінки каталогу та оберіть девайс, що зацікавив"},
        isImg: {"img":""},
        isImg1: {"img1":""},
        isImg2: {"img2":""},
        isImg3: {"img3":""},
        isImg4: {"img4":""},
        isImg5: {"img5":""},
        isBrand: {"brand":"new_device"},
        isStatus: {"status":"order"},

    },
    reducers: {
        setIdDevice: (state, action) => {
            state.idDevice = action.payload;
        },

        setIsTitle: (state, action) => {
            state.isTitle = action.payload;
        },

        setIsText: (state, action) => {
            state.isText = action.payload;
        },


        setIsImg: (state, action) => {
            state.isImg = action.payload;
        },

        setIsImg1: (state, action) => {
            state.isImg1 = action.payload;
        },
        setIsImg2: (state, action) => {
            state.isImg2 = action.payload;
        },
        setIsImg3: (state, action) => {
            state.isImg3 = action.payload;
        },
        setIsImg4: (state, action) => {
            state.isImg4 = action.payload;
        },
        setIsImg5: (state, action) => {
            state.isImg5 = action.payload;
        },
        setIsBrand: (state, action) => {
            state.isBrand = action.payload;
        },
        setIsStatus: (state, action) => {
            state.isStatus = action.payload;
        },
    
    
    }
});

export const { setIdDevice, setIsTitle, setIsText, setIsImg, setIsImg1, setIsImg2, setIsImg3, setIsImg4, setIsImg5, setIsBrand, setIsStatus} = idDeviceSlice.actions;

export default idDeviceSlice.reducer;