import { createSlice } from "@reduxjs/toolkit"; 
import getAllProduct from "../../api/getAllProducts";

const productsSlice = createSlice({
    name: 'products',
    initialState: {
        data: [],
        isLoading: false,
    },
    reducers: {

        addNews: (state, action) => {
            state.data = action.payload;
        },

        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        }
    }
});

const {  addNews, setIsLoading } = productsSlice.actions;

const fetchProduct = () => async (dispatch) => {
    dispatch(setIsLoading(true));
    const products = await getAllProduct();

    dispatch(addNews(products));
    dispatch(setIsLoading(false));
}

export {fetchProduct, addNews, setIsLoading};

export default productsSlice.reducer;