import { PureComponent } from "react";
import styles from "./OurBrandsPage.module.scss";
import OurBrands from "../../components/OurBrands/OurBrands";
import { Helmet } from "react-helmet";
import Footer from "../../components/Footer/Footer";

class OurBrandsPage extends PureComponent {
  scrollto = () => {
    window.scrollTo(0, 0);
  };

  componentDidMount() {

    this.scrollto();

  }
  render() {
    return (
      <div className={styles.ourBrandsPageRoot}>
        <Helmet>
          <title>Наші бренди</title>
          <meta
            name="description"
            content="Бренди, представниками яких є компанія Д8: Crestron, Sonos, Screen Innovations, Origin Acoustic, Unitech, ArtNovion, Atelier Luxus"
          />
          <link rel="canonical" href="https://brands.d8.com.ua/brands" />
        </Helmet>
        <div className={styles.brandsPage}>
          <OurBrands />
        </div>
        <div className={styles.brandsFooter}>
        <Footer />
        </div>

      </div>
    );
  }
}

export default OurBrandsPage;
